import React from 'react';
import {useFetchFinances} from "../hooks/useFetchFinances";

const DeliveryAgreement = () => {
    useFetchFinances()

    return (
        <>
            <div className="card w-full shadow-xl bg-base-200 rounded">
                <div className="card-body py-5">
                    <h1 className="uppercase text-xl md:text-2xl mb-4">ექსპრეს კურიერის მომსახურების პირობები</h1>
                    <ol className="list-decimal pl-6 text-sm md:text-base">
                        <li>ტარიფები დღგ-ს ჩათვლით(თბილისი):</li>
                        <ul className="list-disc pl-5">
                            <li>0.1კგ-დან 10კგ-მდე / 5 ლარი</li>
                            <li>10კგ-დან 30კგ-მდე / 10 ლარი</li>
                            <li>30კგ-დან 100კგ-მდე / 15 ლარი</li>
                            <li>100კგ-დან 300კგ-მდე / 20 ლარი</li>
                            <li>300კგ-დან 500კგ-მდე / 30 ლარი</li>
                            <li>500კგ-დან 1000კგ-მდე / 50 ლარი</li>
                        </ul>
                        <li>ამანათების რაოდენობა შეზღუდული არ არის, ტარიფი დაანგარიშდება ჯამური წონის მიხედვით.</li>
                        <li>მომსახურების საფასური ჩამოგეჭრებათ ბალანსიდან.</li>
                        <li>4) საკურიერო მომსახურება შესაძლებელია ყოველდღე, კვირის გარდა. შაბათიდან პარასკევის ჩათვლით, 15:00 საათამდე გაკეთებული გამოძახება შესრულდება იმავე დღეს, 15:00 საათის შემდეგ გაკეთებული გამოძახება შესრულდება მომდევნო სამუშაო დღეს. შაბათს, 14 საათამდე გაკეთებული გამოძახება შესრულდება იმავე დღეს, 14:00 საათის შემდეგ გაკეთებული გამოძახება კი შესრულდება მომდევნო სამუშაო დღეს.</li>
                        <li>კურიერს გზავნილ(ებ)ი მიაქვს გამოძახების მომენტში დაფიქსირებულ მისამართზე.</li>
                        <li>მიმღები პირი ვალდებულია გარკვევით მიუთითოს მისი მისამართი და გზავნილის მიღების მომენტში თან იქონიოს პირადობის დამადასტურებელი საბუთი.</li>
                        <li>კურიერი გზავნილ(ებ)ის ატანას სართულებზე არ ახდენს. გარდა გამონაკლისი შემთხვევებისა (მაგ: მიმღები არის შეზღუდული შესაძლებლობის პირი)</li>
                        <li>მისამართზე მისვლის დროს კურიერი რეკავს მითითებულ მობილურ ნომერზე არუმეტეს 3 ჯერ, 2 წუთიანი ინტერვალებით. იმ შემთხვევაში თუ მიმღები არ უპასუხებს მობილურ ტელეფონს, კურიერი 6 წუთის შემდეგ ტოვებს გამოძახების ადგილს.</li>
                        <li>მიმღების მიზეზით გაუქმებული გამოძახების შემთხვევაში, მომსახურების საფასური არ ანაზღაურდება.</li>
                        <li>თუ ამანათი განსაბაჟებელია მიმღების მიერ წინასწარ უნდა იქნეს გამოგზავნილი ელ-ფოსტაზე შესყიდვის ინვოისი. საბაჟო დეკლარაციას კურიერი გადმოგცემთ გზავნილთან ერთად.</li>
                        <li>კურიერის მიერ არაკეთილსინდისიერად შესრულებული ვალდებულება შესაძლებელია გასაჩივრდეს წერილობით და მოითხოვოთ მომსახურებაში გადახდილი თანხის ანაზღაურება.</li>
                        <li>კომპანია უფლებას იტოვებს ნებისმიერ დროს ცალმხრივად შევცვალოს ზემოთ აღნიშნული პირობები. (ბოლო განახლება 02/11/2018)</li>
                    </ol>
                </div>
            </div>
        </>
    );
};

export default DeliveryAgreement;