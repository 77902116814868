import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import {IWarehouse} from "../models/IWarehouse";

export const warehouseAPI = createApi({
    reducerPath: 'warehouseAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_URL}`,
        credentials: "include",
        prepareHeaders: (headers) => {
            const auth = JSON.parse(localStorage.getItem('user') as any)

            if (auth?.token) {
                headers.set('authorization', `Bearer ${auth.token}`)
            }

            headers.set('accept', 'application/json')

            return headers
        }
    }),
    tagTypes: ['Warehouses'],
    endpoints: (build) => ({
        fetchAllWarehouses: build.query<IWarehouse, null>({
            query: () => ({
                url: `/warehouses`,
            }),
            transformResponse: (response: { data: IWarehouse }, meta, arg) => response.data,
            providesTags: result => ['Warehouses'],
        }),
    })
})