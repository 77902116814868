import React, {useState} from 'react';
import Sidebar from "./components/Sidebar";
import AppRouter from "./components/AppRouter";
import Navbar from "./components/Navbar";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useLocalStorage from "use-local-storage";
import AuthRouter from "./components/AuthRouter";
import { AuthContext } from './context/AuthContex';
import { LoadingContext } from './context/LoadingContex';
import Loading from "./components/Loading";

function App() {
    const defaultDark = window.matchMedia('(prefers-color-scheme: dark)').matches
    const [theme, setTheme] = useLocalStorage('theme', defaultDark ? 'dark' : 'light')
    const [menuIsOpen, setMenuIsOpen] = useState(false)
    const [loggedIn, setLoggedIn] = useLocalStorage('loggedIn', false)
    const [auth, setAuth] = useLocalStorage('user', null)
    const [loading, setLoading] = useState(false)
    const [finances, setFinances] = useState({
        "balance": "0.00",
        "unpaid": "0.00",
    });

    const toggleMenu = () => setMenuIsOpen(!menuIsOpen)

    return (
        <AuthContext.Provider value={{
            loggedIn,
            setLoggedIn,
            auth,
            setAuth,
            finances,
            setFinances
        }}>
            {loggedIn ?
                <div className="bg-base-100 drawer drawer-mobile" data-theme={theme}>
                    {loading && <Loading/>}
                    <input id="drawer" type="checkbox" className="drawer-toggle" onChange={toggleMenu}/>
                    <LoadingContext.Provider value={{
                        loading,
                        setLoading
                    }}>
                        <div className="drawer-content" style={{scrollBehavior: "smooth", scrollPaddingTop: "5rem"}}>
                            <Navbar theme={theme} setTheme={setTheme}/>
                            <div className="px-4 pt-1 pb-5 sm:pt-5">
                                <AppRouter/>
                            </div>
                        </div>
                        <Sidebar menuIsOpen={menuIsOpen}  />
                    </LoadingContext.Provider>
                </div>
                :
                <AuthRouter theme={theme}/>
            }
            <ToastContainer theme={theme === 'dark' ? 'dark' : 'light'} />
        </AuthContext.Provider>
    );
}

export default App;
