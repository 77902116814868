import React, {useContext, useEffect, useState} from 'react';
import {trusteeAPI} from "../services/TrusteeService";
import {LoadingContext} from "../context/LoadingContex";
import {TrusteeContext} from '../context/TrusteeContex';
import RecordNotFound from "../components/RecordNotFound";
import Trustee from "../components/Trustee";
import Pagination from '../components/Pagination';
import TrusteeModal from "../components/Modals/TrusteeModal";
import {useFetchFinances} from "../hooks/useFetchFinances";

const Trustees = () => {
    useFetchFinances()

    const initialState = {
        trustee: {
            name: '',
            personal_number: '',
            phone: ''
        },
        loading: false,
        edit: false,
        btnText: 'დამატება'
    }

    const [page, setPage] = useState(1)
    const {data: trustees, error, isFetching} = trusteeAPI.useFetchAllTrusteesQuery(page)
    const [createTrustee] = trusteeAPI.useCreateTrusteeMutation()
    const [updateTrustee] = trusteeAPI.useUpdateTrusteeMutation()
    const [deleteTrustee] = trusteeAPI.useDeleteTrusteeMutation()

    const [formData, setFormData] = useState(initialState)
    const {setLoading} = useContext(LoadingContext)

    useEffect(() => {
        setLoading(isFetching)
        // eslint-disable-next-line
    }, [isFetching])

    return (
        <TrusteeContext.Provider value={{
            createTrustee,
            updateTrustee,
            deleteTrustee,
            formData,
            setFormData,
            setPage,
            setLoading
        }}>
            <label htmlFor="trustee-modal" className="btn btn-sm sm:btn-md btn-primary uppercase" onClick={() => setFormData(initialState)}>მიდობილი პირის დამატება</label>
            <div className="md:grid md:grid-cols-2 xl:grid-cols-3 gap-x-6 mt-4">
                {error && <h1>Failed to load data</h1>}
                {trustees && trustees.data.length === 0 &&
                    <RecordNotFound />
                }
                {trustees && trustees.data.map(trustee =>
                    <Trustee key={trustee.id} trustee={trustee}/>
                )}
            </div>
            {trustees && trustees.meta.last_page > 1 &&
                <Pagination currentPage={trustees.meta.current_page} lastPage={trustees.meta.last_page} setPage={setPage} />
            }
            <TrusteeModal />
        </TrusteeContext.Provider>
    );
};

export default Trustees;