import React, {useContext, useEffect} from 'react';
import {parcelAPI} from "../services/ParcelService";
import {warehouseAPI} from "../services/WarehouseService";
import {LoadingContext} from "../context/LoadingContex";
import {useFetchFinances} from "../hooks/useFetchFinances";

const Dashboard = () => {
    useFetchFinances()

    const {data: stats, error, isFetching} = parcelAPI.useFetchStatsQuery(null)
    const {data: warehouses} = warehouseAPI.useFetchAllWarehousesQuery(null)
    const {setLoading} = useContext(LoadingContext)

    useEffect(() => {
        setLoading(isFetching)
        // eslint-disable-next-line
    }, [isFetching])

    return (
        <>
            {error && <h1>Failed to load data</h1>}
            {stats &&
                <>
                    <div className="stats stats-vertical lg:stats-horizontal shadow-xl w-full bg-base-200 rounded">
                        <div className="stat">
                            <div className="stat-title uppercase text-xs sm:text-base">დასადეკლარირებელი</div>
                            <div className="stat-value text-xl sm:text-3xl">{stats.undeclared} <span className="text-sm sm:text-xl uppercase">ამანათი</span></div>
                        </div>
                        <div className="stat">
                            <div className="stat-title uppercase text-xs sm:text-base">გადაუხდელი</div>
                            <div className="stat-value text-xl sm:text-3xl">{stats.unpaid} <span className="text-sm sm:text-xl uppercase">ამანათი</span></div>
                        </div>
                        <div className="stat">
                            <div className="stat-title uppercase text-xs sm:text-base">მიღებული</div>
                            <div className="stat-value text-xl sm:text-3xl">{stats.total_weight} <span className="text-sm sm:text-xl uppercase">კგ</span></div>
                        </div>
                    </div>
                    <div className="h-6 lg:h-10" />
                </>
            }
            {warehouses &&
                <div className="grid gap-4 lg:grid-cols-2">
                    <table className="table table-compact w-full shadow-xl">
                        <thead>
                        <tr>
                            <th className="p-3 bg-base-200 uppercase" colSpan={2}>ჩინეთის მისამართი</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>First Name</td>
                            <td>{warehouses.china.first_name}</td>
                        </tr>
                        <tr>
                            <td>Last Name</td>
                            <td>{warehouses.china.last_name}</td>
                        </tr>
                        <tr>
                            <td>Mark</td>
                            <td>{warehouses.china.mark}</td>
                        </tr>
                        <tr>
                            <td>Address</td>
                            <td className="whitespace-pre-wrap">{warehouses.china.address}</td>
                        </tr>
                        <tr>
                            <td>District</td>
                            <td>{warehouses.china.district}</td>
                        </tr>
                        <tr>
                            <td>City</td>
                            <td>{warehouses.china.city}</td>
                        </tr>
                        <tr>
                            <td>Province</td>
                            <td>{warehouses.china.province}</td>
                        </tr>
                        <tr>
                            <td>Post Code</td>
                            <td>{warehouses.china.post_code}</td>
                        </tr>
                        <tr>
                            <td>Telephone</td>
                            <td>{warehouses.china.telephone}</td>
                        </tr>
                        <tr>
                            <td>Cell Phone</td>
                            <td>{warehouses.china.cellphone}</td>
                        </tr>
                        </tbody>
                    </table>
                    <table className="table table-compact w-full shadow-xl">
                        <thead>
                        <tr>
                            <th className="p-3 bg-base-200 uppercase" colSpan={2}>თურქეთის მისამართი</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>First Name</td>
                            <td>{warehouses.turkey.first_name}</td>
                        </tr>
                        <tr>
                            <td>Last Name</td>
                            <td>{warehouses.turkey.last_name}</td>
                        </tr>
                        <tr>
                            <td>Mark</td>
                            <td>{warehouses.turkey.mark}</td>
                        </tr>
                        <tr>
                            <td>Address</td>
                            <td className="whitespace-pre-wrap">{warehouses.turkey.address}</td>
                        </tr>
                        <tr>
                            <td>District</td>
                            <td>{warehouses.turkey.district}</td>
                        </tr>
                        <tr>
                            <td>Province</td>
                            <td>{warehouses.turkey.province}</td>
                        </tr>
                        <tr>
                            <td>Neighborhood</td>
                            <td>{warehouses.turkey.neighborhood}</td>
                        </tr>
                        <tr>
                            <td>Address Title</td>
                            <td className="whitespace-pre-wrap">{warehouses.turkey.address_title}</td>
                        </tr>
                        <tr>
                            <td>ZIP</td>
                            <td>{warehouses.turkey.zip}</td>
                        </tr>
                        <tr>
                            <td>Phone number</td>
                            <td>{warehouses.turkey.phone_number}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            }
        </>
    );
};

export default Dashboard;