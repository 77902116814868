import React from 'react';

const Loading = () => {
    return (
        <div className="modal visible opacity-100 pointer-events-auto">
            <div className="modal-box w-auto">
                <progress className="progress w-56"></progress>
            </div>
        </div>
    );
};

export default Loading;