import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import {ITrustee} from "../models/ITrustee";

interface ResponseProps {
    data: ITrustee[]
    meta: any
}

export const trusteeAPI = createApi({
    reducerPath: 'trusteeAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_URL}`,
        credentials: "include",
        prepareHeaders: (headers) => {
            const auth = JSON.parse(localStorage.getItem('user') as any)

            if (auth?.token) {
                headers.set('authorization', `Bearer ${auth.token}`)
            }

            headers.set('accept', 'application/json')

            return headers
        }
    }),
    tagTypes: ['Trustee'],
    endpoints: (build) => ({
        fetchAllTrustees: build.query<ResponseProps, number>({
            query: (page: number) => ({
                url: `/user-trustees`,
                params: {
                    page: page
                }
            }),
            //transformResponse: (response: { data: ITrustee[] }, meta, arg) => response.data,
            providesTags: result => ['Trustee'],
        }),
        createTrustee: build.mutation<ITrustee, ITrustee>({
            query: (trustee) => ({
                url: `/user-trustees`,
                method: 'POST',
                body: trustee
            }),
            invalidatesTags: ['Trustee']
        }),
        updateTrustee: build.mutation<ITrustee, ITrustee>({
            query: (trustee) => ({
                url: `/user-trustees/${trustee.id}`,
                method: 'PUT',
                body: trustee
            }),
            invalidatesTags: ['Trustee']
        }),
        deleteTrustee: build.mutation<ITrustee, ITrustee>({
            query: (trustee) => ({
                url: `/user-trustees/${trustee.id}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['Trustee']
        }),
    })
})