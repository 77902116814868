import React from 'react';
import {useFetchFinances} from "../hooks/useFetchFinances";

const DeliveryAgreement = () => {
    useFetchFinances()

    return (
        <>
            <div className="card w-full shadow-xl bg-base-200 rounded">
                <div className="card-body py-5">
                    <h1 className="uppercase text-xl md:text-2xl mb-4">ამანათის დადეკლარირების პირობები</h1>
                    <ol className="list-decimal pl-6 text-sm md:text-base">
                        <li>ღირებულება მიუთითეთ ლარში (მაგ: 55.00).</li>
                        <li>დეკლარირება სავალდებულოა და უნდა დადეკლარირდეს საწყობში მისვლის ან ტრანზიტში ყოფნის დროს.</li>
                        <li>ერთი პირის ან საიტის მიერ გამოგზავნილი 300 ლარზე მეტი ღირებულების ან 30 კგ-ზე მეტი წონის ამანათ(ებ)ი, იბეგრება საბაჟო დღგ-თი... (18%).</li>
                        <li>შესაძლოა ამანათი არ სცდებოდეს ზემოთ მოცემულ რომელიმე ზღვარს, თუმცა მასში მოთავსებული 3-ზე მეტი ერთგვაროვანი ნივთები გახდეს მისი საბაჟო დღგ-თი დაბეგვრის მიზეზი. (საბაჟომ მიიჩნიოს კომერციული დანიშნულების პროდუქციად).</li>
                        <li>დაუდეკლარირებელი ამანათი, ვერ გაივლის საბაჟო პროცედურებს.</li>
                        <li>არასწორი ინფორმაციის მოწოდების შემთხვევაში, პასუხისმგებლობა ეკისრება ამანათის მიმღებს (ისჯება ჯარიმით).</li>
                        <li>ფიზიკური გზავნილის შემთხვევაში, გამომგზავნის ველში ჩაწერეთ "ფიზიკური გზავნილი".</li>
                    </ol>
                    <blockquote className="p-4 my-4 border-l-4 border-gray-300 bg-base-100 text-sm md:text-base">თუ ამანათის ღირებულება არის 300 ლარზე ნაკლები და მისი წონაც 30კგ-ზე ნაკლებია, მაგრამ მაინც გსურთ ამანათის განბაჟება, მონიშნეთ ველი "მსურს განბაჟება".</blockquote>
                </div>
            </div>
        </>
    );
};

export default DeliveryAgreement;