import React, {useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {parcelAPI} from "../services/ParcelService";
import {LoadingContext} from "../context/LoadingContex";
import {ParcelContext} from '../context/ParcelContex';
import ParcelsNav from '../components/ParcelsNav';
import RecordNotFound from "../components/RecordNotFound";
import Parcel from "../components/Parcel";
import Pagination from "../components/Pagination";
import ParcelModal from "../components/Modals/ParcelModal";
import RecipientModal from "../components/Modals/RecipientModal";
import DeclarationModal from "../components/Modals/DeclarationModal";
import {useFetchFinances} from "../hooks/useFetchFinances";
import InvoiceModal from "../components/Modals/InvoiceModal";

const Parcels = () => {
    useFetchFinances()

    const recipientState = {
        name: '',
        personal_number: '',
        phone: ''
    }

    const declarationState = {
        city_id: 0,
        newFiles: [],
        seller: '',
        products: [
            {
                product_code_id: 0,
                price: 0
            }
        ],
        needs_customs_clearance: false,
        disabled: false,
        tracking: '',
        pre: false
    }

    const {status} = useParams();
    const [page, setPage] = useState(1)
    const {data: parcels, error, isFetching} = parcelAPI.useFetchAllParcelsQuery({status: status, page: page})
    const [createParcel] = parcelAPI.useCreateParcelMutation()
    const [updateParcelRecipient] = parcelAPI.useUpdateParcelRecipientMutation()
    const [updateParcelDeclaration] = parcelAPI.useUpdateParcelDeclarationMutation()
    const [deleteParcel] = parcelAPI.useDeleteParcelMutation()
    const [deleteParcelInvoice] = parcelAPI.useDeleteParcelInvoiceMutation()
    const [payParcels] = parcelAPI.usePayParcelsMutation();

    const [recipient, setRecipient] = useState(recipientState)
    const [declaration, setDeclaration] = useState(declarationState)
    const [invoice, setInvoice] = useState(null)
    const {setLoading} = useContext(LoadingContext)

    useEffect(() => {
        setLoading(isFetching)
        // eslint-disable-next-line
    }, [isFetching])

    const addTracking = () => {
        declarationState['pre'] = true;

        setDeclaration(declarationState);
    }

    return (
        <ParcelContext.Provider value={{
            setPage,
            createParcel,
            updateParcelRecipient,
            updateParcelDeclaration,
            deleteParcel,
            deleteParcelInvoice,
            recipient,
            setRecipient,
            declaration,
            setDeclaration,
            invoice,
            setInvoice,
            payParcels
        }}>
            <ParcelsNav />
            {error && <h1>Failed to load data</h1>}
            {typeof status === 'undefined' && <label onClick={addTracking} htmlFor="declaration-modal" className="btn btn-sm sm:btn-md btn-primary uppercase">მისაღები ამანათის დამატება</label>}
            {parcels && parcels.data.length === 0 &&
                <div className="md:grid md:grid-cols-2 xl:grid-cols-3 gap-x-6 mt-4">
                    <RecordNotFound />
                </div>
            }
            <div className="md:grid md:grid-cols-2 gap-x-6">
                {parcels && parcels.data.map(parcel =>
                    <Parcel key={parcel.id} parcel={parcel} />
                )}
            </div>
            {parcels && parcels.meta.last_page > 1 &&
                <Pagination currentPage={parcels.meta.current_page} lastPage={parcels.meta.last_page} setPage={setPage} />
            }
            <ParcelModal />
            <RecipientModal />
            <DeclarationModal />
            <InvoiceModal />
        </ParcelContext.Provider>
    );
};

export default Parcels;