import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import {ISetting} from "../models/ISetting";

export const settingAPI = createApi({
    reducerPath: 'settingAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_URL}`,
        credentials: "include",
        prepareHeaders: (headers) => {
            const auth = JSON.parse(localStorage.getItem('user') as any)

            if (auth?.token) {
                headers.set('authorization', `Bearer ${auth.token}`)
            }

            headers.set('accept', 'application/json')

            return headers
        }
    }),
    tagTypes: ['Setting'],
    endpoints: (build) => ({
        fetchSettings: build.query<ISetting, null>({
            query: () => ({
                url: `/settings`,
            }),
            transformResponse: (response: { data: ISetting }, meta, arg) => response.data,
            providesTags: result => ['Setting'],
        }),
        updateSetting: build.mutation<ISetting, ISetting>({
            query: (setting) => ({
                url: `/settings`,
                method: 'PATCH',
                body: setting
            }),
            invalidatesTags: ['Setting']
        }),
    })
})