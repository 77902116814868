import React, {useContext, useEffect, useState} from 'react';
import {cityAPI} from "../services/CityService";
import {settingAPI} from "../services/SettingService";
import {LoadingContext} from "../context/LoadingContex";
import {useFetchFinances} from "../hooks/useFetchFinances";

const Settings = () => {
    useFetchFinances()

    const initialState = {
        city_id: 0,
        email: '',
        name: '',
        name_ka: '',
        code: '',
        phone: '',
        password: '',
        password_confirmation: '',
        notifications: {
            sms: {
                received: false,
                sent: false,
                arrived: false
            },
            email:{
                received: false,
                sent: false,
                arrived: false
            }
        },
    }

    const {data: cities} = cityAPI.useFetchAllCitiesQuery('branch')
    const {data: settings, error, isFetching} = settingAPI.useFetchSettingsQuery(null)
    const [updateSetting] = settingAPI.useUpdateSettingMutation()

    const [formData, setFormData] = useState(initialState)
    const {loading, setLoading} = useContext(LoadingContext)

    useEffect(() => {
        setLoading(isFetching)
        // eslint-disable-next-line
    }, [isFetching])

    useEffect(() => {
        if(settings) {
            setFormData(settings)
            setFormData((prevState: any) => ({
                ...prevState,
                password: '',
                password_confirmation: ''
            }))
        }
        // eslint-disable-next-line
    }, [settings])

    const onChange = (e: any) => {
        if (e.target.type === 'checkbox') {
            const target = e.target.id.split('.')

            return setFormData((prevState: any) => ({
                ...prevState,
                [target[0]]: {
                    ...prevState[target[0]],
                    [target[1]]: {
                        ...prevState[target[0]][target[1]],
                        [target[2]]: e.target.checked
                    }
                },
            }))
        }

        setFormData((prevState) => ({
            ...prevState,
            [e.target.id]: e.target.value,
        }))
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault()

        setLoading(true)

        await updateSetting(formData).then((payload: any) => {
            if (payload.data) {
                setFormData((prevState: any) => ({
                    ...prevState,
                    password: '',
                    password_confirmation: ''
                }))
            }
        })
    }

    return (
        <>
            {error && <h1>Failed to load data</h1>}
            {settings &&
                <>
                    <div className="card w-full shadow-xl bg-base-200 rounded">
                        <div className="card-body py-5">
                            <form className="w-full md:grid gap-4 md:grid-cols-2">
                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text text-xs sm:text-sm uppercase">ფილიალი</span>
                                    </label>
                                    <select
                                        id="city_id"
                                        className="select select-sm sm:select-md select-bordered font-normal"
                                        value={formData.city_id}
                                        onChange={onChange}
                                        disabled={loading}
                                    >
                                        {cities && cities.map(city =>
                                            <option key={city.id} value={city.id}>{city.name}</option>
                                        )}
                                    </select>
                                </div>
                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text text-xs sm:text-sm uppercase">ელ. ფოსტა</span>
                                    </label>
                                    <input
                                        id="email"
                                        type="email"
                                        className="input input-sm sm:input-md input-bordered"
                                        value={formData.email}
                                        onChange={onChange}
                                        disabled={loading}
                                        readOnly={true}
                                    />
                                </div>
                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text text-xs sm:text-sm uppercase">{settings.type === 'TRB' ? 'კომპანიის სახელი' : 'სახელი და გვარი'} (ლათინურად)</span>
                                    </label>
                                    <input
                                        id="name"
                                        type="text"
                                        className="input input-sm sm:input-md input-bordered"
                                        value={formData.name}
                                        onChange={onChange}
                                        disabled={loading}
                                        readOnly={true}
                                    />
                                </div>
                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text text-xs sm:text-sm uppercase">{settings.type === 'TRB' ? 'კომპანიის სახელი' : 'სახელი და გვარი'} (ქართულად)</span>
                                    </label>
                                    <input
                                        id="name_ka"
                                        type="text"
                                        className="input input-sm sm:input-md input-bordered"
                                        value={formData.name_ka}
                                        onChange={onChange}
                                        disabled={loading}
                                        readOnly={true}
                                    />
                                </div>
                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text text-xs sm:text-sm uppercase">{settings.type === 'TRB' ? 'საიდენტიფიკაციო კოდი' : 'პირადი ნომერი'}</span>
                                    </label>
                                    <input
                                        id="code"
                                        type="text"
                                        className="input input-sm sm:input-md input-bordered"
                                        value={formData.code}
                                        onChange={onChange}
                                        disabled={loading}
                                        readOnly={true}
                                    />
                                </div>
                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text text-xs sm:text-sm uppercase">ტელეფონის ნომერი</span>
                                    </label>
                                    <input
                                        id="phone"
                                        type="text"
                                        className="input input-sm sm:input-md input-bordered"
                                        value={formData.phone}
                                        onChange={onChange}
                                        disabled={loading}
                                        readOnly={true}
                                    />
                                </div>
                                <div className="md:col-span-2 text-xs mt-4">* თუ არ გსურთ პაროლის შეცვლა, დატოვეთ ცარიელი.</div>
                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text text-xs sm:text-sm uppercase">პაროლი</span>
                                    </label>
                                    <input
                                        id="password"
                                        type="password"
                                        className="input input-sm sm:input-md input-bordered"
                                        value={formData.password}
                                        onChange={onChange}
                                        disabled={loading}
                                    />
                                </div>
                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text text-xs sm:text-sm uppercase">გაიმეორეთ პაროლი</span>
                                    </label>
                                    <input
                                        id="password_confirmation"
                                        type="password"
                                        className="input input-sm sm:input-md input-bordered"
                                        value={formData.password_confirmation}
                                        onChange={onChange}
                                        disabled={loading}
                                    />
                                </div>
                                <div className="mt-3 md:mt-0">
                                    <label className="label"><span className="label-text text-xs sm:text-sm uppercase">SMS შეტყობინებები</span></label>
                                    <div className="form-control">
                                        <label className="label cursor-pointer">
                                            <span className="label-text text-xs sm:text-sm">ამანათის მიღება საწყობში</span>
                                            <input
                                                id="notifications.sms.received"
                                                checked={formData.notifications.sms.received}
                                                onChange={onChange}
                                                disabled={loading}
                                                type="checkbox"
                                                className="toggle toggle-primary"
                                            />
                                        </label>
                                    </div>
                                    <div className="form-control">
                                        <label className="label cursor-pointer">
                                            <span className="label-text text-xs sm:text-sm">ამანათის გამოგზავნა</span>
                                            <input
                                                id="notifications.sms.sent"
                                                checked={formData.notifications.sms.sent}
                                                onChange={onChange}
                                                disabled={loading}
                                                type="checkbox"
                                                className="toggle toggle-primary"
                                            />
                                        </label>
                                    </div>
                                    <div className="form-control">
                                        <label className="label cursor-pointer">
                                            <span className="label-text text-xs sm:text-sm">ამანათის ჩამოსვლა</span>
                                            <input
                                                id="notifications.sms.arrived"
                                                checked={formData.notifications.sms.arrived}
                                                onChange={onChange}
                                                disabled={loading}
                                                type="checkbox"
                                                className="toggle toggle-primary"
                                            />
                                        </label>
                                    </div>
                                </div>
                                <div className="mt-3 md:mt-0">
                                    <label className="label"><span className="label-text text-xs sm:text-sm uppercase">ელ. ფოსტის შეტყობინებები</span></label>
                                    <div className="form-control">
                                        <label className="label cursor-pointer">
                                            <span className="label-text text-xs sm:text-sm">ამანათის მიღება საწყობში</span>
                                            <input
                                                id="notifications.email.received"
                                                checked={formData.notifications.email.received}
                                                onChange={onChange}
                                                disabled={loading}
                                                type="checkbox"
                                                className="toggle toggle-primary"
                                            />
                                        </label>
                                    </div>
                                    <div className="form-control">
                                        <label className="label cursor-pointer">
                                            <span className="label-text text-xs sm:text-sm">ამანათის გამოგზავნა</span>
                                            <input
                                                id="notifications.email.sent"
                                                checked={formData.notifications.email.sent}
                                                onChange={onChange}
                                                disabled={loading}
                                                type="checkbox"
                                                className="toggle toggle-primary"
                                            />
                                        </label>
                                    </div>
                                    <div className="form-control">
                                        <label className="label cursor-pointer">
                                            <span className="label-text text-xs sm:text-sm">ამანათის ჩამოსვლა</span>
                                            <input
                                                id="notifications.email.arrived"
                                                checked={formData.notifications.email.arrived}
                                                onChange={onChange}
                                                disabled={loading}
                                                type="checkbox"
                                                className="toggle toggle-primary"
                                            />
                                        </label>
                                    </div>
                                </div>
                                <div className="flex justify-center md:col-span-2 mt-3 md:mt-2">
                                    <button className={'btn btn-sm sm:btn-md btn-primary uppercase' + (loading ? ' loading' : '')} onClick={handleSubmit}>შენახვა</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </>
            }
        </>
    );
};

export default Settings;