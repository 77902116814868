import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import {IAddress} from "../models/IAddress";

interface ResponseProps {
    data: IAddress[]
    meta: any
}

export const addressAPI = createApi({
    reducerPath: 'addressAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_URL}`,
        credentials: "include",
        prepareHeaders: (headers) => {
            const auth = JSON.parse(localStorage.getItem('user') as any)

            if (auth?.token) {
                headers.set('authorization', `Bearer ${auth.token}`)
            }

            headers.set('accept', 'application/json')

            return headers
        }
    }),
    tagTypes: ['Address'],
    endpoints: (build) => ({
        fetchAllAddresses: build.query<ResponseProps, number>({
            query: (page: number) => ({
                url: `/user-addresses`,
                params: {
                    page: page
                },
            }),
            providesTags: result => ['Address'],
        }),
        createAddress: build.mutation<IAddress, IAddress>({
            query: (address) => ({
                url: `/user-addresses`,
                method: 'POST',
                body: address
            }),
            invalidatesTags: ['Address']
        }),
        updateAddress: build.mutation<IAddress, IAddress>({
            query: (address) => ({
                url: `/user-addresses/${address.id}`,
                method: 'PUT',
                body: address
            }),
            invalidatesTags: ['Address']
        }),
        deleteAddress: build.mutation<IAddress, IAddress>({
            query: (address) => ({
                url: `/user-addresses/${address.id}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['Address']
        }),
    })
})