import React, {FC, useState} from 'react';
import Logo from "../../../assets/images/logo.svg";
import {Link} from "react-router-dom";
import {authAPI} from "../../../services/AuthService";

interface ThemeProps {
    theme: string
}

const Forgot: FC<ThemeProps> = ({theme}) => {
    const [forgot] = authAPI.useForgotMutation()

    const [email, setEmail] = useState('')
    const [loading, setLoading] = useState(false)

    const handleSubmit = async (e: any) => {
        e.preventDefault()

        setLoading(true)

        await forgot(email).then((payload: any) => {
            if (payload.data) {
                setEmail('')
            }
        })

        setLoading(false)
    }

    return (
        <div className="hero min-h-screen bg-base-200" data-theme={theme}>
            <div className="hero-content">
                <div>
                    <div className="text-center mb-7">
                        <a href="/"><img className="inline-block w-44 sm:w-60" src={Logo} alt="Transporter" /></a>
                    </div>
                    <div className="card w-72 sm:w-96 shadow-2xl bg-base-100">
                        <div className="card-body">
                            <div className="form-control">
                                <label className="label">
                                    <span className="label-text text-xs sm:text-sm uppercase">ელ. ფოსტა</span>
                                </label>
                                <input
                                    id="email"
                                    type="text"
                                    className="input input-sm sm:input-md input-bordered"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    disabled={loading}
                                />
                            </div>
                            <div className="form-control mt-4">
                                <button className={"btn btn-sm sm:btn-md btn-primary uppercase" + (loading ? ' loading' : '')} onClick={handleSubmit}>პაროლის აღდგენა</button>
                            </div>
                            <div className="text-center mt-2">
                                <Link to="/login" className={"text-xs sm:text-sm link link-hover" + (loading ? ' pointer-events-none' : '')}>ავტორიზაცია</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Forgot;