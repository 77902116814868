import React, {FC, useContext, useState} from 'react';
import {Link} from "react-router-dom";
import Logo from "../assets/images/logo.svg";
import {AuthContext} from "../context/AuthContex";
import {authAPI} from "../services/AuthService";

interface ThemeProps {
    theme: string
    setTheme: (theme: string) => void
}

const Navbar: FC<ThemeProps> = ({theme, setTheme}) => {
    const [logout] = authAPI.useLogoutMutation()
    const {setLoggedIn, auth, setAuth} = useContext(AuthContext)
    const [activeDark] = useState(theme === 'dark' ? 'on' : 'off')
    const [activeLight] = useState(theme === 'light' ? 'on' : 'off')

    const toggleTheme = () => setTheme(theme === 'light' ? 'dark' : 'light')

    const handleLogout = async (e: any) => {
        e.preventDefault()

        await logout(null).then((payload: any) => {
            if (payload.data) {
                setLoggedIn(false)
                setAuth(null)
            }
        })
    }

    return (
        <>
            <div className="sticky top-0 z-30 flex h-16 w-full justify-center bg-opacity-90 backdrop-blur transition-all duration-100 bg-base-100 text-base-content">
                <nav className="navbar w-full">
                    <div className="flex flex-1 md:gap-0 lg:gap-2">
                        <label htmlFor="drawer" className="btn btn-sm sm:btn-md btn-square btn-ghost drawer-button lg:hidden">
                            <svg xmlns="http://www.w3.org/2000/svg" className="inline-block h-4 w-4 stroke-current sm:h-6 sm:w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                            </svg>
                        </label>
                        <div className="flex items-center gap-2 lg:hidden">
                            <Link to="/" aria-current="page" aria-label="Homepage" className="flex-0 btn btn-sm sm:btn-md btn-ghost px-2">
                                <img src={Logo} className="block h-7 sm:h-9" alt="Transporter" />
                            </Link>
                        </div>
                    </div>
                    <div className="flex-0">
                        <label className="swap swap-rotate btn btn-sm sm:btn-md gap-1 normal-case btn-ghost">
                            <input type="checkbox" onChange={toggleTheme}/>
                            <svg className={"swap-" + activeDark + " w-4 h-4 fill-current sm:w-6 sm:h-6"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                <path
                                    d="M5.64,17l-.71.71a1,1,0,0,0,0,1.41,1,1,0,0,0,1.41,0l.71-.71A1,1,0,0,0,5.64,17ZM5,12a1,1,0,0,0-1-1H3a1,1,0,0,0,0,2H4A1,1,0,0,0,5,12Zm7-7a1,1,0,0,0,1-1V3a1,1,0,0,0-2,0V4A1,1,0,0,0,12,5ZM5.64,7.05a1,1,0,0,0,.7.29,1,1,0,0,0,.71-.29,1,1,0,0,0,0-1.41l-.71-.71A1,1,0,0,0,4.93,6.34Zm12,.29a1,1,0,0,0,.7-.29l.71-.71a1,1,0,1,0-1.41-1.41L17,5.64a1,1,0,0,0,0,1.41A1,1,0,0,0,17.66,7.34ZM21,11H20a1,1,0,0,0,0,2h1a1,1,0,0,0,0-2Zm-9,8a1,1,0,0,0-1,1v1a1,1,0,0,0,2,0V20A1,1,0,0,0,12,19ZM18.36,17A1,1,0,0,0,17,18.36l.71.71a1,1,0,0,0,1.41,0,1,1,0,0,0,0-1.41ZM12,6.5A5.5,5.5,0,1,0,17.5,12,5.51,5.51,0,0,0,12,6.5Zm0,9A3.5,3.5,0,1,1,15.5,12,3.5,3.5,0,0,1,12,15.5Z"/>
                            </svg>
                            <svg className={"swap-" + activeLight + " w-4 h-4 fill-current sm:w-6 sm:h-6"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                <path
                                    d="M21.64,13a1,1,0,0,0-1.05-.14,8.05,8.05,0,0,1-3.37.73A8.15,8.15,0,0,1,9.08,5.49a8.59,8.59,0,0,1,.25-2A1,1,0,0,0,8,2.36,10.14,10.14,0,1,0,22,14.05,1,1,0,0,0,21.64,13Zm-9.5,6.69A8.14,8.14,0,0,1,7.08,5.22v.27A10.15,10.15,0,0,0,17.22,15.63a9.79,9.79,0,0,0,2.1-.22A8.11,8.11,0,0,1,12.14,19.73Z"/>
                            </svg>
                        </label>
                        <div className="dropdown dropdown-end">
                            <div tabIndex={0} className="btn btn-sm sm:btn-md gap-1 normal-case btn-ghost">
                                <div className="indicator">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4 sm:w-6 sm:h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                                    </svg>
                                    <span className="badge badge-xs badge-warning indicator-item"/>
                                </div>
                            </div>
                        </div>
                        <div className="dropdown dropdown-end">
                            <div tabIndex={0} className="btn btn-sm sm:btn-md gap-1 normal-case btn-ghost">
                                <svg xmlns="http://www.w3.org/2000/svg" className="sm:hidden h-4 w-4 sm:h-6 sm:w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                                </svg>
                                <span className="hidden sm:inline uppercase">{auth.user}</span>
                                <svg className="hidden h-2 w-2 fill-current ml-2 sm:inline-block"
                                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048">
                                    <path d="M1799 349l242 241-1017 1017L7 590l242-241 775 775 775-775z" />
                                </svg>
                            </div>
                            <div className="dropdown-content bg-base-200 text-base-content rounded-t-box rounded-b-box top-px mt-10 sm:mt-14 w-72 sm:w-52 overflow-y-auto shadow-2xl">
                                <ul className="menu menu-compact gap-1 px-2 pb-2 pt-4 sm:p-3" tabIndex={0}>
                                    <li className="px-4 uppercase sm:hidden">{auth.user}</li>
                                    <li>
                                        <button className="flex" onClick={handleLogout}>გასვლა</button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </>
    );
};

export default Navbar;