import React, {useContext, useEffect, useState} from 'react';
import {addressAPI} from "../services/AddressService"
import {LoadingContext} from "../context/LoadingContex";
import {AddressContext} from '../context/AddressContex';
import Address from "../components/Address";
import RecordNotFound from "../components/RecordNotFound";
import Pagination from "../components/Pagination";
import AddressModal from "../components/Modals/AddressModal";
import {useFetchFinances} from "../hooks/useFetchFinances";

const Addresses = () => {
    useFetchFinances()

    const initialState = {
        _address: {
            city_id: 0,
            address: '',
            name: '',
            personal_number: '',
            phone: '',
            comment: '',
        },
        edit: false,
        btnText: 'დამატება'
    }

    const [page, setPage] = useState(1)
    const {data: addresses, error, isFetching} = addressAPI.useFetchAllAddressesQuery(page)
    const [createAddress] = addressAPI.useCreateAddressMutation()
    const [deleteAddress] = addressAPI.useDeleteAddressMutation()
    const {setLoading} = useContext(LoadingContext)

    useEffect(() => {
        setLoading(isFetching)
        // eslint-disable-next-line
    }, [isFetching])

    const [formData, setFormData] = useState(initialState)

    return (
        <AddressContext.Provider value={{
            createAddress,
            deleteAddress,
            formData,
            setFormData,
            setPage
        }}>
            <label htmlFor="address-modal" className="btn btn-sm sm:btn-md btn-primary uppercase" onClick={() => setFormData(initialState)}>მისამართის დამატება</label>
            <div className="md:grid md:grid-cols-2 xl:grid-cols-3 gap-x-6 mt-4">
                {error && <h1>Failed to load data</h1>}
                {addresses && addresses.data.length === 0 &&
                    <RecordNotFound />
                }
                {addresses && addresses.data.map(address =>
                    <Address key={address.id} address={address} />
                )}
            </div>
            {addresses && addresses.meta.last_page > 1 &&
                <Pagination currentPage={addresses.meta.current_page} lastPage={addresses.meta.last_page} setPage={setPage} />
            }
            <AddressModal />
        </AddressContext.Provider>
    );
};

export default Addresses;