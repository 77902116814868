import React, {useContext, useRef} from 'react';
import Logo from "../../assets/images/logo.svg";
import {useReactToPrint} from "react-to-print";
import {ParcelContext} from "../../context/ParcelContex";

const InvoiceModal = () => {
    const {invoice} = useContext(ParcelContext)
    const componentRef = useRef(null);

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: invoice?.tracking
    });

    const shippingPrice = invoice?.shipping?.price?.toLocaleString('ka-GE', {
        style: 'currency',
        currency: 'GEL',
        minimumFractionDigits: 2,
    });

    return (
        <>
            <input type="checkbox" id="invoice-modal" className="modal-toggle"/>
            <div className="modal">
                <div className="modal-box w-11/12 max-w-2xl">
                    <div className="absolute left-1/2 -translate-x-1/2 top-2">
                        <button className="btn btn-primary btn-sm md:btn-md" onClick={handlePrint}>ამობეჭდვა</button>
                    </div>
                    <label htmlFor="invoice-modal" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
                    {invoice &&
                        <div ref={componentRef} className="bg-white rounded-lg shadow-lg px-4 md:px-8 py-5 md:py-10 w-full">
                            <div className="flex items-center justify-between mb-8">
                                <img className="h-10 mr-2" src={Logo} alt="Transporter"/>
                                <div className="text-gray-700 text-right">
                                    <div className="text-xs sm:text-sm font-bold">ინვოისი #: {invoice.id}</div>
                                    <div className="text-xs sm:text-sm">თარიღი: {invoice.received_at}</div>
                                </div>
                            </div>
                            <table className="w-full text-gray-700 text-xs sm:text-sm md:text-base">
                                <tbody>
                                <tr>
                                    <td className="text-left">გადამხდელი: {invoice.user?.name}</td>
                                    <td className="text-right">შპს ტრანსპორტერი</td>
                                </tr>
                                <tr>
                                    <td className="text-left">იდენტიფიკატორი: {invoice.user?.suite}</td>
                                    <td className="text-right">ს/კ 205265513</td>
                                </tr>
                                <tr>
                                    <td className="text-left">ტელ: {invoice.user?.phone}</td>
                                    <td className="text-right">ტელ: 219 33 19</td>
                                </tr>
                                </tbody>
                            </table>
                            <div className="border-b-2 border-gray-300 h-8 mb-8"></div>
                            <table className="w-full text-left mb-8 text-xs sm:text-sm md:text-base">
                                <thead>
                                <tr>
                                    <th className="text-gray-700 font-bold py-2">მომსახურება</th>
                                    <th className="text-gray-700 font-bold py-2 text-right">თანხა</th>
                                    <th className="text-gray-700 font-bold py-2 text-right">დღგ</th>
                                    <th className="text-gray-700 font-bold py-2 text-right">სულ</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td className="text-gray-700">
                                        საფოსტო გადაზიდვა
                                        <div className="text-xs">ამანათის ნომერი: {invoice.tracking}</div>
                                    </td>
                                    <td className="text-gray-700 text-right whitespace-nowrap pl-2">{shippingPrice}</td>
                                    <td className="text-gray-700 text-right whitespace-nowrap pl-2">0.00 ₾</td>
                                    <td className="text-gray-700 text-right whitespace-nowrap pl-2">{shippingPrice}</td>
                                </tr>
                                </tbody>
                            </table>
                            <div className="flex justify-end items-center mb-8">
                                <div className="text-gray-700 mr-2 text-xs sm:text-sm md:text-base">ჯამი:</div>
                                <div className="text-gray-700 font-bold text-sm sm:text-base md:text-xl">{shippingPrice}</div>
                            </div>
                            <div className="border-t-2 border-gray-300 pt-8 mb-8 text-xs sm:text-sm md:text-base">
                                <div className="text-center text-gray-700">
                                    <span className="font-bold">დანიშნულება</span>: ტრანსპორტირების ღირებულება, {invoice.user?.suite}
                                </div>
                                <blockquote className="p-4 my-4 border-l-4 border-gray-300 bg-gray-50">
                                    <p className="leading-relaxed text-gray-900 font-bold">სს "პროკრედიტ ბანკი"</p>
                                    <p className="text-gray-900">ბანკის კოდი: MIBGGE22</p>
                                    <p className="text-gray-900">ანგარიშის ნომერი: GE25PC0093600100024283</p>
                                </blockquote>
                                <blockquote className="p-4 my-4 border-l-4 border-gray-300 bg-gray-50">
                                    <p className="leading-relaxed text-gray-900 font-bold">სს "თიბისი ბანკი"</p>
                                    <p className="text-gray-900">ბანკის კოდი: TBCBGE22</p>
                                    <p className="text-gray-900">ანგარიშის ნომერი: GE86TB5480336020100006</p>
                                </blockquote>
                            </div>
                            <div className="flex flex-col md:flex-row gap-1 sm:gap-2 items-center justify-between text-xs sm:text-sm md:text-base">
                                <a className="flex items-center gap-x-2 text-gray-700" rel="noreferrer" href="https://transporter.ge" target="_blank">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 md:w-6 h-4 md:h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418" />
                                    </svg>
                                    www.transporter.ge
                                </a>
                                <a className="flex items-center gap-x-2 text-gray-700" href="mailto:sales@transporter.ge">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 md:w-6 h-4 md:h-6">
                                        <path strokeLinecap="round" d="M16.5 12a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 10-2.636 6.364M16.5 12V8.25" />
                                    </svg>
                                    sales@transporter.ge
                                </a>
                                <div className="flex items-center gap-x-2 text-gray-700">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 md:w-6 h-4 md:h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
                                    </svg>
                                    219 33 19
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </>
    );
};

export default InvoiceModal;