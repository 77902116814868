import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import {ICity} from "../models/ICity";

export const cityAPI = createApi({
    reducerPath: 'cityAPI',
    baseQuery: fetchBaseQuery({baseUrl: `${process.env.REACT_APP_API_URL}`}),
    tagTypes: ['City'],
    endpoints: (build) => ({
        fetchAllCities: build.query<ICity[], string>({
            query: (type: string) => ({
                url: `/cities`,
                params: {
                    type: type
                },
            }),
            transformResponse: (response: { data: ICity[] }, meta, arg) => response.data,
            providesTags: result => ['City'],
        }),
    })
})