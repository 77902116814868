import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import {IUserType} from "../models/IUserType";

export const userTypeAPI = createApi({
    reducerPath: 'userTypeAPI',
    baseQuery: fetchBaseQuery({baseUrl: `${process.env.REACT_APP_API_URL}`}),
    tagTypes: ['UserType'],
    endpoints: (build) => ({
        fetchAllUserTypes: build.query<IUserType[], null>({
            query: () => ({
                url: `/user-types`,
            }),
            transformResponse: (response: { data: IUserType[] }, meta, arg) => response.data,
            providesTags: result => ['UserType'],
        }),
    })
})