import React, {FC} from 'react';
import {Routes, Route, Navigate} from "react-router-dom";
import Login from "../pages/Auth/Login";
import Register from "../pages/Auth/Register";
import Forgot from "../pages/Auth/Password/Forgot";
import Reset from '../pages/Auth/Password/Reset';

interface ThemeProps {
    theme: string
}

const AuthRouter: FC<ThemeProps> = ({theme}) => {
    return (
        <Routes>
            <Route path="/login" element={<Login theme={theme} />} />
            <Route path="/register" element={<Register theme={theme} />} />
            <Route path="/password/forgot" element={<Forgot theme={theme} />} />
            <Route path="/password/reset/:token" element={<Reset theme={theme} />} />
            <Route path="*" element={<Navigate replace to="/login" />} />
        </Routes>
    );
};

export default AuthRouter;