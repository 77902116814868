import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import './assets/fonts/Roboto_GEOMt.eot';
import './assets/fonts/Roboto_GEOMt.ttf';
import './assets/fonts/Roboto_GEOMt.woff';
import './assets/fonts/Roboto_GEOMt.woff2';
import './assets/fonts/Roboto_GEONus.eot';
import './assets/fonts/Roboto_GEONus.ttf';
import './assets/fonts/Roboto_GEONus.woff';
import './assets/fonts/Roboto_GEONus.woff2';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import { setupStore } from './store/store';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const store = setupStore();

root.render(
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
);