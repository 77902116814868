import React, {FC, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import Logo from "../../../assets/images/logo.svg";
import {authAPI} from "../../../services/AuthService";

interface ThemeProps {
    theme: string
}

const initialState = {
    email: '',
    password: '',
    password_confirmation: '',
    token: ''
}

const Reset: FC<ThemeProps> = ({theme}) => {
    const {token} = useParams()
    const navigate = useNavigate()
    const [reset] = authAPI.useResetMutation()

    const [formData, setFormData] = useState(initialState)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setFormData((prevState: any) => ({
            ...prevState,
            token: token
        }))
    }, [token])

    const onChange = (e: any) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.id]: e.target.value,
        }))
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault()

        setLoading(true)

        await reset(formData).then((payload: any) => {
            if (payload.data) {
                setFormData(initialState)
                navigate('/login')
            }
        })

        setLoading(false)
    }

    return (
        <div className="hero min-h-screen bg-base-200" data-theme={theme}>
            <div className="hero-content">
                <div>
                    <div className="text-center mb-7">
                        <a href="/"><img className="inline-block w-44 sm:w-60" src={Logo} alt="Transporter" /></a>
                    </div>
                    <div className="card w-72 sm:w-96 shadow-2xl bg-base-100">
                        <div className="card-body">
                            <div className="form-control">
                                <label className="label">
                                    <span className="label-text text-xs sm:text-sm uppercase">ელ. ფოსტა</span>
                                </label>
                                <input
                                    id="email"
                                    type="text"
                                    className="input input-sm sm:input-md input-bordered"
                                    value={formData.email}
                                    onChange={onChange}
                                    disabled={loading}
                                />
                            </div>
                            <div className="form-control">
                                <label className="label">
                                    <span className="label-text text-xs sm:text-sm uppercase">ახალი პაროლი</span>
                                </label>
                                <input
                                    id="password"
                                    type="password"
                                    className="input input-sm sm:input-md input-bordered"
                                    value={formData.password}
                                    onChange={onChange}
                                    disabled={loading}
                                />
                            </div>
                            <div className="form-control">
                                <label className="label">
                                    <span className="label-text text-xs sm:text-sm uppercase">გაიმეორეთ პაროლი</span>
                                </label>
                                <input
                                    id="password_confirmation"
                                    type="password"
                                    className="input input-sm sm:input-md input-bordered"
                                    value={formData.password_confirmation}
                                    onChange={onChange}
                                    disabled={loading}
                                />
                            </div>
                            <div className="form-control mt-4">
                                <button className={"btn btn-sm sm:btn-md btn-primary uppercase" + (loading ? ' loading' : '')} onClick={handleSubmit}>პაროლის აღდგენა</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Reset;