import {combineReducers, configureStore, isFulfilled} from "@reduxjs/toolkit";
import {
    MiddlewareAPI,
    isRejectedWithValue,
    Middleware,
} from '@reduxjs/toolkit'
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {IError} from "../models/IError";
import {warehouseAPI} from "../services/WarehouseService";
import {parcelAPI} from "../services/ParcelService";
import {cityAPI} from "../services/CityService";
import {addressAPI} from "../services/AddressService";
import {trusteeAPI} from "../services/TrusteeService";
import {settingAPI} from "../services/SettingService";
import {authAPI} from "../services/AuthService";
import {userTypeAPI} from "../services/UserTypeService";

const rootReducer = combineReducers({
    [authAPI.reducerPath]: authAPI.reducer,
    [userTypeAPI.reducerPath]: userTypeAPI.reducer,
    [parcelAPI.reducerPath]: parcelAPI.reducer,
    [warehouseAPI.reducerPath]: warehouseAPI.reducer,
    [cityAPI.reducerPath]: cityAPI.reducer,
    [addressAPI.reducerPath]: addressAPI.reducer,
    [trusteeAPI.reducerPath]: trusteeAPI.reducer,
    [settingAPI.reducerPath]: settingAPI.reducer
})

export const rtkQueryErrorLogger: Middleware =
    (api: MiddlewareAPI) => (next) => (action) => {
        if (isRejectedWithValue(action)) {
            toast.dismiss()

            try {
                const data = action.payload.data as IError
                const isEmpty = typeof data.errors === 'undefined' || Object.keys(data.errors).length === 0

                if (action.payload.status === 401) {
                    toast.error(action.payload.data.message)

                    localStorage.removeItem('loggedIn')
                    localStorage.removeItem('user')
                } else if (data.errors) {
                    Object.values(data.errors).forEach(errors => {
                        toast.error(errors[0])
                    })
                } else if (isEmpty && typeof action.payload.data.message !== 'undefined' && action.payload.data.message !== '') {
                    toast.error(action.payload.data.message)
                } else {
                    toast.error('დაფიქსირდა შეცდომა')
                }
            } catch (e) {
                toast.error('დაფიქსირდა შეცდომა')
            }
        }

        if (isFulfilled(action)) {
            if (action.payload.message) {
                toast.success(action.payload.message)
            }
        }

        return next(action)
    }

export const setupStore = () => {
    return configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware()
                .concat([
                    authAPI.middleware,
                    userTypeAPI.middleware,
                    parcelAPI.middleware,
                    warehouseAPI.middleware,
                    cityAPI.middleware,
                    addressAPI.middleware,
                    trusteeAPI.middleware,
                    settingAPI.middleware,
                    rtkQueryErrorLogger
                ])
    })
}

