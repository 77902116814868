import React, {useContext, useRef, useState} from 'react';
import {ParcelContext} from "../../context/ParcelContex";
import {LoadingContext} from "../../context/LoadingContex";

const ParcelModal = () => {
    const closeModalRef = useRef() as any
    const {setPage, createParcel} = useContext(ParcelContext)
    const {loading, setLoading} = useContext(LoadingContext)
    const [tracking, setTracking] = useState('')

    const handleSubmit = async (e: any) => {
        e.preventDefault()

        setLoading(true)

        await createParcel({tracking})
            .then((payload: any) => {
                if (payload.data) {
                    setPage(1)
                    setTracking('')
                    closeModalRef.current.click()
                }
            })
    }

    return (
        <>
            <input type="checkbox" id="parcel-modal" className="modal-toggle"/>
            <div className="modal">
                <div className="modal-box">
                    <label ref={closeModalRef} htmlFor="parcel-modal" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
                    <h3 className="font-bold text-lg text-center uppercase">ამანათის დამატება</h3>
                    <div className="text-center mt-4">
                        <input
                            id="tracking"
                            type="text"
                            placeholder="თრექინგი"
                            className="input input-bordered w-full max-w-xs"
                            value={tracking}
                            onChange={(e: any) => setTracking(e.target.value)}
                            disabled={loading}
                        />
                    </div>
                    <div className="modal-action justify-center">
                        <button className={'btn btn-sm sm:btn-md btn-primary uppercase' + (loading ? ' loading' : '')} onClick={handleSubmit}>დამატება</button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ParcelModal;