import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import {IParcel} from "../models/IParcel";
import {IParcelQuantity} from "../models/IParcelQuantity";
import {IProductCode} from "../models/IProductCode";
import {IStat} from "../models/IStat";
import {IFinance} from "../models/IFinance";
import {IDelivery} from "../models/IDelivery";
import {ITransaction} from "../models/ITransaction";

interface ResponseParcelProps {
    data: IParcel[]
    meta: any
}

interface ResponseDeliveryProps {
    data: IDelivery[]
    meta: any
}

interface ResponseTransactionProps {
    data: ITransaction[]
    meta: any
}

export const parcelAPI = createApi({
    reducerPath: 'parcelAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_URL}`,
        credentials: "include",
        prepareHeaders: (headers) => {
            const auth = JSON.parse(localStorage.getItem('user') as any)

            if (auth?.token) {
                headers.set('authorization', `Bearer ${auth.token}`)
            }

            headers.set('accept', 'application/json')

            return headers
        }
    }),
    tagTypes: ['Stats', 'Quantities', 'ProductCodes', 'Deliveries', 'Transactions', 'Finances', 'ReadyToDelivery', 'Parcels'],
    endpoints: (build) => ({
        fetchStats: build.query<IStat, null>({
            query: () => ({
                url: `/parcels/stats`,
            }),
            providesTags: result => ['Stats'],
        }),
        fetchQuantities: build.query<IParcelQuantity, null>({
            query: () => ({
                url: `/parcels/quantities`,
            }),
            providesTags: result => ['Quantities'],
        }),
        fetchProductCodes: build.query<IProductCode[], null>({
            query: () => ({
                url: `/parcels/product-codes`,
            }),
            transformResponse: (response: { data: IProductCode[] }, meta, arg) => response.data,
            providesTags: result => ['ProductCodes'],
        }),
        fetchDeliveries: build.query<ResponseDeliveryProps, number>({
            query: (page: number) => ({
                url: `/deliveries`,
                params: {
                    page: page
                },
            }),
            providesTags: result => ['Deliveries'],
        }),
        fetchTransactions: build.query<ResponseTransactionProps, number>({
            query: (page: number) => ({
                url: `/transactions`,
                params: {
                    page: page
                },
            }),
            providesTags: result => ['Transactions'],
        }),
        fetchFinances: build.query<IFinance, null>({
            query: () => ({
                url: `/finances`,
            }),
            transformResponse: (response: { data: IFinance }, meta, arg) => response.data,
            providesTags: result => ['Finances'],
        }),
        fetchReadyForDelivery: build.query<ResponseParcelProps, null>({
            query: () => ({
                url: `/parcels/ready-for-delivery`,
            }),
            providesTags: result => ['ReadyToDelivery'],
        }),
        fetchAllParcels: build.query<ResponseParcelProps, any>({
            query: ({status, page}) => ({
                url: `/parcels`,
                params: {
                    status: status,
                    page: page
                }
            }),
            providesTags: result => ['Parcels'],
        }),
        createParcel: build.mutation<IParcel, any>({
            query: (formData) => ({
                url: `/parcels`,
                method: 'POST',
                body: formData
            }),
            invalidatesTags: ['Parcels', 'Quantities']
        }),
        updateParcelRecipient: build.mutation<IParcel, IParcel>({
            query: (parcel) => ({
                url: `/parcels/${parcel.id}/recipient`,
                method: 'PATCH',
                body: parcel
            }),
            invalidatesTags: ['Parcels']
        }),
        updateParcelDeclaration: build.mutation<IParcel, any>({
            query: (formData) => ({
                url: `/parcels/${formData.get('id')}/declaration`,
                method: 'POST',
                body: formData
            }),
            invalidatesTags: ['Parcels', 'Stats']
        }),
        deleteParcel: build.mutation<IParcel, IParcel>({
            query: (parcel) => ({
                url: `/parcels/${parcel.id}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['Parcels', 'Quantities'],
        }),
        deleteParcelInvoice: build.mutation<IParcel, any>({
            query: ({parcel, invoice}) => ({
                url: `/parcels/${parcel}/delete-invoice/${invoice}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['Parcels']
        }),
        payParcels: build.mutation<IParcel, any>({
            query: (parcels) => ({
                url: `/pay/parcels`,
                method: 'POST',
                body: {
                    parcels: parcels
                }
            }),
            invalidatesTags: ['Parcels', 'Stats', 'Deliveries', 'Transactions', 'Finances', 'ReadyToDelivery']
        }),
        payDelivery: build.mutation<IDelivery, any>({
            query: (formData) => ({
                url: `/pay/delivery`,
                method: 'POST',
                body: formData
            }),
            invalidatesTags: ['Parcels', 'Stats', 'Deliveries', 'Transactions', 'Finances', 'ReadyToDelivery']
        }),
    })
})