import React, {useContext, useRef} from 'react';
import {IAddress} from '../../models/IAddress';
import {cityAPI} from '../../services/CityService';
import {AddressContext} from "../../context/AddressContex";
import {LoadingContext} from "../../context/LoadingContex";

const AddressModal = () => {
    const closeModalRef = useRef() as any
    const {data: cities} = cityAPI.useFetchAllCitiesQuery('delivery')
    const {createAddress, formData, setFormData, setPage} = useContext(AddressContext);
    const {loading, setLoading} = useContext(LoadingContext)

    const onChange = (e: any) => {
        setFormData((prevState: any) => ({
            ...prevState,
            _address: {
                ...prevState['_address'],
                [e.target.id]: e.target.value
            }
        }))
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        setLoading(true)

        await createAddress(formData._address as IAddress)
            .then((payload: any) => {
                if (payload.data) {
                    setPage(1)
                    closeModalRef.current.click()
                }
            })
    }

    return (
        <>
            <input type="checkbox" id="address-modal" className="modal-toggle"/>
            <div className="modal">
                <div className="modal-box">
                    <label ref={closeModalRef} htmlFor="address-modal" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
                    <form className="w-full md:grid gap-4 md:grid-cols-2">
                        <div className="form-control">
                            <label className="label">
                                <span className="label-text text-xs sm:text-sm uppercase">* სახელი და გვარი</span>
                            </label>
                            <input
                                id="name"
                                type="text"
                                className="input input-sm sm:input-md input-bordered"
                                value={formData._address.name}
                                onChange={onChange}
                                disabled={loading}
                            />
                        </div>
                        <div className="form-control">
                            <label className="label">
                                <span className="label-text text-xs sm:text-sm uppercase">* პირადი ნომერი</span>
                            </label>
                            <input
                                id="personal_number"
                                type="text"
                                className="input input-sm sm:input-md input-bordered"
                                value={formData._address.personal_number}
                                onChange={onChange}
                                disabled={loading}
                            />
                        </div>
                        <div className="form-control">
                            <label className="label">
                                <span className="label-text text-xs sm:text-sm uppercase">* ტელეფონის ნომერი</span>
                            </label>
                            <input
                                id="phone"
                                type="text"
                                className="input input-sm sm:input-md input-bordered"
                                value={formData._address.phone}
                                onChange={onChange}
                                disabled={loading}
                            />
                        </div>
                        <div className="form-control">
                            <label className="label">
                                <span className="label-text text-xs sm:text-sm uppercase">* ქალაქი</span>
                            </label>
                            <select
                                id="city_id"
                                className="select select-sm sm:select-md select-bordered font-normal"
                                value={formData._address.city_id}
                                onChange={onChange}
                                disabled={loading}
                            >
                                <option>აირჩიეთ</option>
                                {cities && cities.map(city =>
                                    <option key={city.id} value={city.id}>{city.name}</option>
                                )}
                            </select>
                        </div>
                        <div className="form-control md:col-span-2">
                            <label className="label">
                                <span className="label-text text-xs sm:text-sm uppercase">* მისამართი</span>
                            </label>
                            <input
                                id="address"
                                type="address"
                                className="input input-sm sm:input-md input-bordered"
                                value={formData._address.address}
                                onChange={onChange}
                                disabled={loading}
                            />
                        </div>
                        <div className="form-control md:col-span-2">
                            <label className="label">
                                <span className="label-text text-xs sm:text-sm uppercase">კომენტარი</span>
                            </label>
                            <textarea
                                id="comment"
                                className="textarea textarea-bordered resize-none"
                                value={formData._address.comment}
                                onChange={onChange}
                                disabled={loading}
                            />
                        </div>
                        <div className="flex justify-center md:col-span-2 mt-3 md:mt-2">
                            <button className={'btn btn-sm sm:btn-md btn-primary uppercase' + (loading ? ' loading' : '')} onClick={handleSubmit}>{formData.btnText}</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default AddressModal;