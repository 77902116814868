import React, {FC} from 'react';

interface PaginationProps {
    currentPage: number
    lastPage: number
    setPage: (page:number) => void
}

const Pagination: FC<PaginationProps> = ({currentPage, lastPage, setPage}) => {
    const handlePreviousPage = () => {
        setPage(currentPage - 1)
    }

    const handleNextPage = () => {
        setPage(currentPage + 1)
    }

    return (
        <div className="btn-group mt-7 d-flex justify-center">
            <div className="btn-group">
                <button className={"btn btn-outline" + (currentPage === 1 ? " btn-disabled" : '')} onClick={handlePreviousPage}>«</button>
                <button className="btn btn-outline uppercase">გვერდი ({currentPage}-{lastPage})</button>
                <button className={"btn btn-outline" + (currentPage === lastPage ? " btn-disabled" : '')} onClick={handleNextPage}>»</button>
            </div>
        </div>
    );
};

export default Pagination;