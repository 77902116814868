import React, {FC, useContext} from 'react';
import {IAddress} from '../models/IAddress';
import {AddressContext} from "../context/AddressContex";
import {LoadingContext} from "../context/LoadingContex";

interface AddressItemProps {
    address: IAddress
}

const Address: FC<AddressItemProps> = ({address}) => {
    const {deleteAddress} = useContext(AddressContext)
    const {setLoading} = useContext(LoadingContext)

    const handleRemove = async () => {
        setLoading(true)

        await deleteAddress(address)
    }

    return (
        <div className="bg-base-200 py-3 pl-4 pr-10 rounded shadow-xl relative mt-6">
            <div className="uppercase mb-2">{address.name}</div>
            <div className="text-xs mb-1">პირადი ნომერი: {address.personal_number}</div>
            <div className="text-xs mb-1">ტელეფონის ნომერი: {address.phone}</div>
            <div className="text-xs mb-1">ქალაქი: {address.city}</div>
            <div className="text-xs">მისამართი: {address.address}</div>
            <div className="absolute top-0 right-0 bottom-0 flex flex-col justify-between p-2">
                <button className="btn btn-xs btn-square btn-ghost" onClick={handleRemove}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="red">
                        <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                    </svg>
                </button>
                {address.comment !== null &&
                    <div className="dropdown dropdown-hover dropdown-left dropdown-end">
                        <label tabIndex={0} className="btn btn-xs btn-square btn-ghost cursor-default">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20"
                                 fill="currentColor">
                                <path fillRule="evenodd"
                                      d="M18 13V5a2 2 0 00-2-2H4a2 2 0 00-2 2v8a2 2 0 002 2h3l3 3 3-3h3a2 2 0 002-2zM5 7a1 1 0 011-1h8a1 1 0 110 2H6a1 1 0 01-1-1zm1 3a1 1 0 100 2h3a1 1 0 100-2H6z"
                                      clipRule="evenodd"/>
                            </svg>
                        </label>
                        <div tabIndex={0} className="dropdown-content menu p-2 shadow bg-base-100 rounded w-56 min-h-16">
                            <span className="text-xs">{address.comment}</span>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default Address;