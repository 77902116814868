import React, {useContext, useEffect, useState} from 'react';
import {LoadingContext} from "../context/LoadingContex";
import RecordNotFound from "../components/RecordNotFound";
import Pagination from "../components/Pagination";
import {useFetchFinances} from "../hooks/useFetchFinances";
import {parcelAPI} from "../services/ParcelService";

const Transactions = () => {
    useFetchFinances()

    const [page, setPage] = useState(1)
    const {data: transactions, error, isFetching} = parcelAPI.useFetchTransactionsQuery(page)
    const {setLoading} = useContext(LoadingContext)

    useEffect(() => {
        setLoading(isFetching)
        // eslint-disable-next-line
    }, [isFetching])

    const priceColor = (type: string) => {
        let priceClass;

        switch (type) {
            case 'payment':
                priceClass = 'text-green-500';
                break;
            case 'expense':
                priceClass = 'text-red-500';
                break;
            case 'correction':
                priceClass = 'text-yellow-500';
                break;
        }

        return priceClass;
    }

    return (
        <div>
            {error && <h1>Failed to load data</h1>}
            {transactions && transactions.data.length === 0 &&
                <div className="md:grid md:grid-cols-2 xl:grid-cols-3 gap-x-6">
                    <RecordNotFound />
                </div>
            }
            <div className="md:grid md:grid-cols-2 gap-x-6 md:gap-y-4">
                {transactions && transactions.data.map((transaction, index) =>
                    <div key={index} className="mt-4 md:mt-0">
                        <div className="bg-base-200 py-2 px-3 sm:py-3 sm:px-4 flex items-center justify-between rounded shadow">
                            <div className="flex gap-3 items-center">
                                <div className="avatar placeholder">
                                    <div className="rounded w-12 sm:w-16 bg-base-100">
                                        <span className="text-base sm:text-md uppercase-transform">{transaction.provider || 'TRA'}</span>
                                    </div>
                                </div>
                                <div className="flex flex-col gap-1">
                                    <div className="flex items-center">
                                        <div className="text-xs sm:text-sm break-all uppercase">{transaction.action} {!transaction.provider && transaction.note && '- ' + transaction.note}</div>
                                    </div>
                                    <div className="text-xs sm:text-md">
                                        <div>{transaction.created_at.replace(', 00:00', '')}</div>
                                    </div>
                                </div>
                            </div>
                            <div className={`inline-flex items-center text-base ${priceColor(transaction.type)}`}>
                                {transaction.amount} <span className="ml-1 text-2xs">ლარი</span>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {transactions && transactions.meta.last_page > 1 &&
                <Pagination currentPage={transactions.meta.current_page} lastPage={transactions.meta.last_page} setPage={setPage} />
            }
        </div>
    );
};

export default Transactions;