import React from 'react';

const BalanceModal = () => {
    return (
        <>
            <input type="checkbox" id="balance-modal" className="modal-toggle"/>
            <div className="modal">
                <div className="modal-box">
                    <label htmlFor="balance-modal" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
                    <h3 className="font-bold text-lg text-center uppercase">ბალანსის შევსება</h3>
                    <div className="text-center mt-4">
                        <input type="text" placeholder="თანხა" className="input input-bordered w-full max-w-xs"/>
                    </div>
                    <div className="modal-action justify-center">
                        <button className="btn btn-primary uppercase">შევსება</button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BalanceModal;