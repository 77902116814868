import React from 'react';
import {Routes, Route, Navigate} from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import Parcels from "../pages/Parcels";
import Transactions from "../pages/Transactions";
import Settings from "../pages/Settings";
import Trustees from "../pages/Trustees";
import Addresses from "../pages/Addresses";
import Deliveries from "../pages/Deliveries";
import DeliveryAgreement from "../pages/DeliveryAgreement";
import DeclarationAgreement from "../pages/DeclarationAgreement";

const AppRouter = () => {
    return (
        <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/parcels" element={<Parcels />} />
            <Route path="/parcels/:status" element={<Parcels />} />
            <Route path="/trustees" element={<Trustees />} />
            <Route path="/addresses" element={<Addresses />} />
            <Route path="/deliveries" element={<Deliveries />} />
            <Route path="/transactions" element={<Transactions />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/delivery-agreement" element={<DeliveryAgreement />} />
            <Route path="/declaration-agreement" element={<DeclarationAgreement />} />
            <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>
    );
};

export default AppRouter;