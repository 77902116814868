import React, {useContext, useEffect, useRef, useState} from 'react';
import {LoadingContext} from "../../context/LoadingContex";
import {DeliveryContext} from "../../context/DeliveryContex";
import {addressAPI} from "../../services/AddressService";
import {parcelAPI} from "../../services/ParcelService";
import {Link} from "react-router-dom";
import {IDelivery} from "../../models/IDelivery";

const DeliveryModal = () => {
    const closeModalRef = useRef() as any
    const {data: addresses} = addressAPI.useFetchAllAddressesQuery(1)
    const {data: parcels} = parcelAPI.useFetchReadyForDeliveryQuery(null)
    const {payDelivery, formData, setFormData, setPage} = useContext(DeliveryContext);
    const {loading, setLoading} = useContext(LoadingContext)
    const [price, setPrice] = useState(0)

    const onChange = (e: any) => {
        const name: string = e.target.name
        const value: number = parseInt(e.target.value)
        const weight: number = parseFloat(e.target.getAttribute('data-weight'))

        if (name === 'user_address_id') {
            setFormData({
                ...formData,
                delivery: {
                    ...formData.delivery,
                    user_address_id: value,
                },
            })
        }

        if (name === 'parcels') {
            const updatedParcels = [...formData.delivery.parcels]
            const updateWeights = [...formData.delivery.weights]
            const index = updatedParcels.indexOf(value)

            if (index === -1) {
                updatedParcels.push(value)
                updateWeights.push(weight)
            } else {
                updatedParcels.splice(index, 1)
                updateWeights.splice(index, 1)
            }

            setFormData({
                ...formData,
                delivery: {
                    ...formData.delivery,
                    parcels: updatedParcels,
                    weights: updateWeights
                },
            })
        }
    }

    useEffect(() => {
        calculatePrice()
        // eslint-disable-next-line
    }, [formData])

    const calculatePrice = () => {
        const weight: number = formData.delivery.weights.reduce((accumulator: number, currentValue: number) => accumulator + currentValue, 0)
        let price: number = 0

        if (weight === 0) {
            price = 0
        } else if (weight > 0 && weight < 10) {
            price = 5
        } else if (weight >= 10 && weight < 30) {
            price = 10
        } else if (weight >= 30 && weight < 100) {
            price = 15
        } else if (weight >= 100 && weight < 300) {
            price = 20
        } else if (weight >= 300 && weight < 500) {
            price = 30
        } else {
            price = 50
        }

        setPrice(price)
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        setLoading(true)

        await payDelivery(formData.delivery as IDelivery)
            .then((payload: any) => {
                if (payload.data) {
                    setPage(1)
                    closeModalRef.current.click()
                }
            })
    }

    return (
        <>
            <input type="checkbox" id="delivery-modal" className="modal-toggle"/>
            <div className="modal">
                <div className="modal-box">
                    <label ref={closeModalRef} htmlFor="delivery-modal" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
                    <form className="w-full">
                        <div className="form-control">
                            <div className="label">
                                <span className="label-text text-xs sm:text-sm uppercase">* აირჩიეთ მისამართი</span>
                            </div>
                            <div className="flex flex-col gap-y-3">
                                {addresses && addresses.data.length === 0 &&
                                    <div className="alert shadow-lg rounded">
                                        <span className="text-sm">თქვენი მისამართები არ მოიძებნა.</span>
                                    </div>
                                }
                                {addresses && addresses.data.map((address, index) =>
                                    <label key={index} className="bg-base-200 py-2 px-3 sm:py-3 sm:px-4 flex items-center justify-between rounded shadow cursor-pointer">
                                        <div>
                                            <div className="uppercase text-sm mb-1">{address.name}</div>
                                            <div className="text-xs">მისამართი: {address.city}, {address.address}</div>
                                            {address.comment &&
                                                <div className="text-2xs mt-1">{address.comment}</div>
                                            }
                                        </div>
                                        <div className="ml-4">
                                            <input
                                                type="radio"
                                                name="user_address_id"
                                                className="radio radio-primary block"
                                                value={address.id}
                                                onChange={onChange}
                                                disabled={loading}
                                                checked={formData.delivery.user_address_id === address.id}
                                            />
                                        </div>
                                    </label>
                                )}
                            </div>
                        </div>
                        <div className="form-control mt-2">
                            <div className="label">
                                <span className="label-text text-xs sm:text-sm uppercase">* მონიშნეთ ამანათები</span>
                            </div>
                            <div className="flex flex-col gap-y-3">
                                {parcels && parcels.data.length === 0 &&
                                    <div className="alert shadow-lg rounded">
                                        <span className="text-sm">საკურიერო სერვისით სარგებლობისთვის ამანათები არ მოიძებნა.</span>
                                    </div>
                                }
                                {parcels && parcels.data.map((parcel, index) =>
                                    <label key={index} className="bg-base-200 py-2 px-3 sm:py-3 sm:px-4 flex items-center justify-between rounded shadow cursor-pointer">
                                        <div>
                                            <div className="uppercase text-sm mb-1">{parcel.tracking}</div>
                                            <div className="text-xs">წონა: {parcel.charge_weight} კგ</div>
                                        </div>
                                        <div className="ml-4">
                                            <input
                                                type="checkbox"
                                                name="parcels"
                                                className="checkbox checkbox-primary block"
                                                value={parcel.id}
                                                data-weight={parcel.charge_weight}
                                                checked={formData.delivery.parcels.includes(parcel.id)}
                                                onChange={onChange}
                                                disabled={loading}
                                            />
                                        </div>
                                    </label>
                                )}
                            </div>
                        </div>
                        <div className="flex flex-col sm:flex-row gap-y-4 items-center justify-between md:col-span-2 mt-3 md:mt-2">
                            <Link to="/delivery-agreement" target="_blank" className="order-0 sm:order-none uppercase hover:underline mt-0.5 text-xs sm:text-sm">
                                გამოყენების პირობები
                            </Link>
                            <button
                                className={'btn btn-sm sm:btn-md btn-primary uppercase' + (loading ? ' loading' : '')}
                                onClick={handleSubmit}
                                disabled={price === 0 || formData.delivery.user_address_id === 0}
                            >
                                {formData.btnText}
                                <span className="ml-2">
                                    ({price.toLocaleString('ka-GE', {
                                        style: 'currency',
                                        currency: 'GEL',
                                        minimumFractionDigits: 2,
                                    })})
                                </span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default DeliveryModal;