import React, {useContext, useRef} from 'react';
import {cityAPI} from "../../services/CityService";
import {parcelAPI} from "../../services/ParcelService";
import {ParcelContext} from "../../context/ParcelContex";
import {LoadingContext} from "../../context/LoadingContex";
import {Link} from "react-router-dom";

const DeclarationModal = () => {
    const closeModalRef = useRef() as any
    const fileInputRef = useRef() as any
    const {data: cities} = cityAPI.useFetchAllCitiesQuery('branch')
    const {data: productCodes} = parcelAPI.useFetchProductCodesQuery(null)
    const {createParcel, updateParcelDeclaration, deleteParcelInvoice, declaration, setDeclaration} = useContext(ParcelContext)
    const {loading, setLoading} = useContext(LoadingContext)

    const onChange = (e: any, i: number = -1) => {
        if (i === -1) {
            if (e.target.type === 'file') {
                return setDeclaration((prevState: any) => ({
                   ...prevState,
                   newFiles: e.target.files
                }))
            }

            return setDeclaration((prevState: any) => ({
                ...prevState,
                [e.target.id]: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
            }))
        }

        const { name, value } = e.target;

        const list = {
            ...declaration,
            products: [...declaration.products]
        };

        list.products[i] = Object.assign({}, list.products[i], {
            [name]: name === 'product_code_id' ? parseInt(value) : value
        });

        setDeclaration(list)
    }

    const handleAdd = (e: any) => {
        e.preventDefault()

        setDeclaration({
            ...declaration,
            products: [
                ...declaration.products,
                { product_code_id: 0, price: '' }
            ]
        })
    }

    const handleRemove = (e: any, index: number) => {
        e.preventDefault()

        const list = {
            ...declaration,
            products: [...declaration.products]
        }

        list.products.splice(index, 1)

        setDeclaration(list);
    }

    const handleRemoveFile = async (e: any, index: number) => {
        e.preventDefault()

        setLoading(true)

        await deleteParcelInvoice({parcel: declaration.id, invoice: declaration.files[index].id})
            .then((payload: any) => {
                if (payload.data) {
                    const list = {
                        ...declaration,
                        files: [...declaration.files]
                    }

                    list.files.splice(index, 1)

                    setDeclaration(list)
                }
            })

        setLoading(false)
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault()

        const formData = new FormData()

        if (declaration.pre) {
            formData.append('tracking', declaration.tracking)
        } else {
            formData.append('id', declaration.id)
        }

        formData.append('city_id', declaration.city_id === 0 ? (cities && cities[0].id) : declaration.city_id)
        formData.append('seller', declaration.seller)
        formData.append('needs_customs_clearance', declaration.needs_customs_clearance)

        for (let i=0; i<declaration.products.length; i++) {
            formData.append('products[]', JSON.stringify(declaration.products[i]))
        }

        for (let i=0; i<declaration.newFiles.length; i++) {
            formData.append("files[]", declaration.newFiles[i])
        }

        setLoading(true)

        if (declaration.pre) {
            return await createParcel(formData)
                .then((payload: any) => {
                    if (payload.data) {
                        fileInputRef.current.value = null
                        closeModalRef.current.click()
                    }
                })
        }

        await updateParcelDeclaration(formData)
            .then((payload: any) => {
                if (payload.data) {
                    fileInputRef.current.value = null
                    closeModalRef.current.click()
                }
            })
    }

    return (
        <>
            <input type="checkbox" id="declaration-modal" className="modal-toggle"/>
            <div className="modal">
                <div className="modal-box">
                    <label ref={closeModalRef} htmlFor="declaration-modal" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
                    <form>
                        {declaration.pre &&
                            <div className="form-control">
                                <label className="label">
                                    <span className="label-text text-xs sm:text-sm uppercase">* თრექინგი</span>
                                </label>
                                <input
                                    id="tracking"
                                    type="text"
                                    className="input input-sm sm:input-md input-bordered"
                                    value={declaration.tracking}
                                    onChange={onChange}
                                    disabled={loading || declaration.disabled}
                                />
                            </div>
                        }
                        <div className="form-control">
                            <label className="label">
                                <span className="label-text text-xs sm:text-sm uppercase">ინვოისი</span>
                            </label>
                            <input
                                ref={fileInputRef}
                                id="files"
                                type="file"
                                className="input input-sm sm:input-md input-bordered"
                                style={{paddingLeft:"0", lineHeight:"100%"}}
                                multiple
                                onChange={onChange}
                                disabled={loading || declaration.disabled}
                            />
                            <div className="flex flex-wrap w-full">
                                {declaration.files && declaration.files.map((file: { id: number, name: string, url: string }, i: number) =>
                                    <div key={i} className="w-full mt-2 sm:mt-3 rounded border border-base-300 bg-base-200 flex items-center overflow-hidden">
                                        <span className="text-bold px-3 truncate text-sm sm:text-md">{file.name}</span>
                                        <div className="flex ml-auto h-full">
                                            <a className={"h-full cursor-pointer border-l border-base-300 inline-flex items-center justify-center py-2 px-1.5" + (loading ? ' pointer-events-none': '')}
                                               href={file.url}
                                               target="_blank"
                                               rel="noreferrer"
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                     stroke="currentColor" width="16" height="16"
                                                     className="inline-block" role="presentation">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"></path>
                                                </svg>
                                            </a>
                                            {!declaration.disabled &&
                                                <button className="h-full cursor-pointer border-l border-base-300 inline-flex items-center justify-center py-2 px-1.5"
                                                     onClick={e => handleRemoveFile(e, i)}
                                                     disabled={loading || declaration.disabled}
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                         stroke="currentColor" width="16" height="16"
                                                         className="inline-block text-red-500" role="presentation">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
                                                    </svg>
                                                </button>
                                            }
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="form-control">
                            <label className="label">
                                <span className="label-text text-xs sm:text-sm uppercase">* სელერი</span>
                            </label>
                            <input
                                id="seller"
                                type="text"
                                className="input input-sm sm:input-md input-bordered"
                                value={declaration.seller}
                                onChange={onChange}
                                disabled={loading || declaration.disabled}
                            />
                        </div>
                        <div className="divider uppercase mb-0">ნივთები</div>
                        {declaration.products.map((product: { product_code_id: number, price: number }, i: number) =>
                            <div className="form-control grid grid-cols-3 gap-3 relative pr-9 sm:pr-11" key={i}>
                                <div className="col-span-2">
                                    <label className="label">
                                        <span className="label-text text-xs sm:text-sm uppercase">* კატეგორია</span>
                                    </label>
                                    <select
                                        name="product_code_id"
                                        className="select select-sm sm:select-md select-bordered font-normal w-full"
                                        value={product.product_code_id}
                                        onChange={e => onChange(e, i)}
                                        disabled={loading || declaration.disabled}
                                    >
                                        <option>აირჩიეთ</option>
                                        {productCodes && productCodes.map(productCode =>
                                            <option key={productCode.id} value={productCode.id}>{productCode.name}</option>
                                        )}
                                    </select>
                                </div>
                                <div>
                                    <label className="label">
                                        <span className="label-text text-xs sm:text-sm uppercase whitespace-nowrap">* ფასი <small>(ლარში)</small></span>
                                    </label>
                                    <input
                                        name="price"
                                        type="text"
                                        className="input input-sm sm:input-md input-bordered w-full"
                                        value={product.price}
                                        onChange={e => onChange(e, i)}
                                        disabled={loading || declaration.disabled}
                                    />
                                </div>
                                <button
                                    className={'btn btn-xs sm:btn-sm btn-square btn-error absolute right-0 bottom-1 sm:bottom-2' + (declaration.products.length === 1 ? ' btn-disabled' : '')}
                                    onClick={e => handleRemove(e, i)}
                                    disabled={loading || declaration.disabled}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 sm:h-6 w-4 sm:w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </button>
                            </div>
                        )}
                        <div className="text-center">
                            <button className="btn btn-xs sm:btn-sm btn-success uppercase mt-4" onClick={handleAdd} disabled={loading || declaration.disabled}>
                                ნივთის დამატება
                            </button>
                        </div>
                        <div className="divider mb-0"></div>
                        <div className="form-control">
                            <label className="label">
                                <span className="label-text text-xs sm:text-sm uppercase">* ფილიალი</span>
                            </label>
                            <select
                                id="city_id"
                                className="select select-sm sm:select-md select-bordered font-normal w-full"
                                value={declaration.city_id}
                                onChange={onChange}
                                disabled={loading || declaration.disabled}
                            >
                                {cities && cities.map(city =>
                                    <option key={city.id} value={city.id}>{city.name}</option>
                                )}
                            </select>
                        </div>
                        <div className="divider mb-0"></div>
                        <div className="modal-action justify-between items-center mt-4">
                            <div className="form-control">
                                <div className="flex items-center">
                                    <div className="dropdown dropdown-top dropdown-hover">
                                        <div className="mr-1">
                                            <label tabIndex={0}>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="stroke-info h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                </svg>
                                            </label>
                                        </div>
                                        <div tabIndex={0} className="dropdown-content p-4 shadow bg-base-300 text-sm rounded-box w-60 sm:w-96 mb-2">
                                            თუ ამანათის ღირებულება არის 300 ლარზე ნაკლები და მისი წონაც 30კგ-ზე ნაკლებია, მაგრამ მაინც გსურთ ამანათის განბაჟება, მონიშნეთ "მსურს განბაჟება"
                                        </div>
                                    </div>
                                    <label className="label cursor-pointer">
                                        <span className="label-text text-xs sm:text-sm mr-3 uppercase">მსურს განბაჟება</span>
                                        <input
                                            id="needs_customs_clearance"
                                            checked={declaration.needs_customs_clearance}
                                            onChange={onChange}
                                            disabled={loading || declaration.disabled}
                                            type="checkbox"
                                            className="toggle toggle-primary"
                                        />
                                    </label>
                                </div>
                            </div>
                            <button
                                className={'btn btn-sm sm:btn-md btn-primary uppercase' + (loading ? ' loading' : '')}
                                onClick={handleSubmit}
                                disabled={declaration.disabled}
                            >{declaration.pre ? 'დამატება' : 'შენახვა'}</button>
                        </div>
                        <div className="divider mb-2"></div>
                        <div className="text-center">
                            <Link to="/declaration-agreement" target="_blank" className="uppercase hover:underline mt-0.5">
                                გამოყენების პირობები
                            </Link>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default DeclarationModal;