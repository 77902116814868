import React, {FC, useContext, useState} from 'react';
import Logo from "../../assets/images/logo.svg";
import {Link} from "react-router-dom";
import {cityAPI} from "../../services/CityService";
import {userTypeAPI} from "../../services/UserTypeService";
import {authAPI} from "../../services/AuthService";
import {AuthContext} from "../../context/AuthContex";

interface ThemeProps {
    theme: string
}

const initialState = {
    user_type: '',
    email: '',
    name: '',
    name_ka: '',
    code: '',
    phone: '',
    password: '',
    password_confirmation: '',
    city_id: 0,
}

const Register: FC<ThemeProps> = ({theme}) => {
    const {data: cities} = cityAPI.useFetchAllCitiesQuery('branch')
    const {data: userTypes} = userTypeAPI.useFetchAllUserTypesQuery(null)
    const [register] = authAPI.useRegisterMutation()

    const [formData, setFormData] = useState(initialState)
    const [loading, setLoading] = useState(false)

    const {setLoggedIn, setAuth} = useContext(AuthContext)

    const onChange = (e: any) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.id]: e.target.value,
        }))
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault()

        setLoading(true)

        await register(formData).then((payload: any) => {
            if (payload.data) {
                setLoggedIn(true)
                setAuth({type: payload.data.type, user: payload.data.user, token: payload.data.access_token})
            }
        })

        setLoading(false)
    }

    return (
        <div className="hero min-h-screen bg-base-200" data-theme={theme}>
            <div className="hero-content md:w-full">
                <div className="md:w-full md:max-w-3xl">
                    <div className="text-center mb-7">
                        <a href="/"><img className="inline-block w-44 sm:w-60" src={Logo} alt="Transporter" /></a>
                    </div>
                    <div className="card w-72 sm:w-96 md:w-full shadow-2xl bg-base-100">
                        <div className="card-body">
                            <form className="md:grid gap-4 md:grid-cols-2">
                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text text-xs sm:text-sm uppercase">ანგარიშის ტიპი</span>
                                    </label>
                                    <select
                                        id="user_type"
                                        className="select select-sm sm:select-md select-bordered font-normal"
                                        value={formData.user_type}
                                        onChange={onChange}
                                        disabled={loading}
                                    >
                                        <option>აირჩიეთ</option>
                                        {userTypes && userTypes.map(userType =>
                                            <option key={userType.type} value={userType.type}>{userType.name}</option>
                                        )}
                                    </select>
                                </div>
                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text text-xs sm:text-sm uppercase">ელ. ფოსტა</span>
                                    </label>
                                    <input
                                        id="email"
                                        type="text"
                                        className="input input-sm sm:input-md input-bordered"
                                        value={formData.email}
                                        onChange={onChange}
                                        disabled={loading}
                                    />
                                </div>
                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text text-xs sm:text-sm uppercase">პაროლი</span>
                                    </label>
                                    <input
                                        id="password"
                                        type="password"
                                        className="input input-sm sm:input-md input-bordered"
                                        value={formData.password}
                                        onChange={onChange}
                                        disabled={loading}
                                    />
                                </div>
                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text text-xs sm:text-sm uppercase">გაიმეორეთ პაროლი</span>
                                    </label>
                                    <input
                                        id="password_confirmation"
                                        type="password"
                                        className="input input-sm sm:input-md input-bordered"
                                        value={formData.password_confirmation}
                                        onChange={onChange}
                                        disabled={loading}
                                    />
                                </div>
                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text text-xs sm:text-sm uppercase">{formData.user_type === 'TRB' ? 'კომპანიის სახელი' : 'სახელი და გვარი'} (ლათინურად)</span>
                                    </label>
                                    <input
                                        id="name"
                                        type="text"
                                        className="input input-sm sm:input-md input-bordered"
                                        value={formData.name}
                                        onChange={onChange}
                                        disabled={loading}
                                    />
                                </div>
                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text text-xs sm:text-sm uppercase">{formData.user_type === 'TRB' ? 'კომპანიის სახელი' : 'სახელი და გვარი'} (ქართულად)</span>
                                    </label>
                                    <input
                                        id="name_ka"
                                        type="text"
                                        className="input input-sm sm:input-md input-bordered"
                                        value={formData.name_ka}
                                        onChange={onChange}
                                        disabled={loading}
                                    />
                                </div>
                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text text-xs sm:text-sm uppercase">{formData.user_type === 'TRB' ? 'საიდენტიფიკაციო კოდი' : 'პირადი ნომერი'}</span>
                                    </label>
                                    <input
                                        id="code"
                                        type="text"
                                        className="input input-sm sm:input-md input-bordered"
                                        value={formData.code}
                                        onChange={onChange}
                                        disabled={loading}
                                    />
                                </div>
                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text text-xs sm:text-sm uppercase">მობილურის ნომერი</span>
                                    </label>
                                    <input
                                        id="phone"
                                        type="text"
                                        className="input input-sm sm:input-md input-bordered"
                                        value={formData.phone}
                                        onChange={onChange}
                                        disabled={loading}
                                    />
                                </div>
                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text text-xs sm:text-sm uppercase">ფილიალი</span>
                                    </label>
                                    <select
                                        id="city_id"
                                        className="select select-sm sm:select-md select-bordered font-normal"
                                        value={formData.city_id}
                                        onChange={onChange}
                                        disabled={loading}
                                    >
                                        <option>აირჩიეთ</option>
                                        {cities && cities.map(city =>
                                            <option key={city.id} value={city.id}>{city.name}</option>
                                        )}
                                    </select>
                                </div>
                                <div className="md:flex md:justify-center md:col-span-2 form-control mt-4">
                                    <button className={"btn btn-sm sm:btn-md btn-primary uppercase" + (loading ? ' loading' : '')} onClick={handleSubmit}>რეგისტრაცია</button>
                                </div>
                            </form>
                            <div className="text-center mt-2">
                                <Link to="/login" className={"text-xs sm:text-sm link link-hover" + (loading ? ' pointer-events-none' : '')}>ავტორიზაცია</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Register;