import React, {FC, useContext, useState} from 'react';

import Logo from "../../assets/images/logo.svg";
import {Link} from "react-router-dom";
import {authAPI} from "../../services/AuthService";
import {AuthContext} from "../../context/AuthContex";
import {parcelAPI} from "../../services/ParcelService";
import {warehouseAPI} from "../../services/WarehouseService";
import {addressAPI} from "../../services/AddressService";
import {trusteeAPI} from "../../services/TrusteeService";
import {settingAPI} from "../../services/SettingService";
import {useDispatch} from "react-redux";

interface ThemeProps {
    theme: string
}

const initialState = {
    email: '',
    password: '',
}

const Login: FC<ThemeProps> = ({theme}) => {
    const {setLoggedIn, setAuth} = useContext(AuthContext)
    const [login] = authAPI.useLoginMutation()

    const [formData, setFormData] = useState(initialState)
    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch()

    const onChange = (e: any) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.id]: e.target.value,
        }))
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault()

        setLoading(true)

        await login(formData).then((payload: any) => {
            if (payload.data) {
                setLoggedIn(true)
                setAuth({type: payload.data.type, user: payload.data.user, token: payload.data.access_token})
                dispatch(warehouseAPI.util.resetApiState())
                dispatch(parcelAPI.util.resetApiState())
                dispatch(trusteeAPI.util.resetApiState())
                dispatch(addressAPI.util.resetApiState())
                dispatch(settingAPI.util.resetApiState())
            }
        })

        setLoading(false)
    }

    return (
        <div className="hero min-h-screen bg-base-200" data-theme={theme}>
            <div className="hero-content">
                <div>
                    <div className="text-center mb-7">
                        <a href="/"><img className="inline-block w-44 sm:w-60" src={Logo} alt="Transporter" /></a>
                    </div>
                    <div className="card w-72 sm:w-96 shadow-2xl bg-base-100">
                        <div className="card-body">
                            <div className="form-control">
                                <label className="label">
                                    <span className="label-text text-xs sm:text-sm uppercase">ელ. ფოსტა</span>
                                </label>
                                <input
                                    id="email"
                                    type="text"
                                    className="input input-sm sm:input-md input-bordered"
                                    value={formData.email}
                                    onChange={onChange}
                                    disabled={loading}
                                />
                            </div>
                            <div className="form-control">
                                <label className="label">
                                    <span className="label-text text-xs sm:text-sm uppercase">პაროლი</span>
                                </label>
                                <input
                                    id="password"
                                    type="password"
                                    className="input input-sm sm:input-md input-bordered"
                                    value={formData.password}
                                    onChange={onChange}
                                    disabled={loading}
                                />
                                <label className="label">
                                    <Link to="/password/forgot" className={"label-text-alt link link-hover" + (loading ? ' pointer-events-none' : '')}>დაგავიწყდათ პაროლი?</Link>
                                </label>
                            </div>
                            <div className="form-control mt-2">
                                <button className={"btn btn-sm sm:btn-md btn-primary uppercase" + (loading ? ' loading' : '')} onClick={handleSubmit}>ავტორიზაცია</button>
                            </div>
                            <div className="text-center mt-2">
                                <Link to="/register" className={"text-xs sm:text-sm link link-hover" + (loading ? ' pointer-events-none' : '')}>რეგისტრაცია</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;