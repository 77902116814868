import React, {useContext, useRef} from 'react';
import {ITrustee} from '../../models/ITrustee';
import {TrusteeContext} from "../../context/TrusteeContex";
import {LoadingContext} from "../../context/LoadingContex";

const TrusteeModal = () => {
    const closeModalRef = useRef() as any
    const {createTrustee, updateTrustee, formData, setFormData, setPage} = useContext(TrusteeContext)
    const {loading, setLoading} = useContext(LoadingContext)
    
    const onChange = (e: any) => {
        setFormData((prevState: any) => ({
            ...prevState,
            trustee: {
                ...prevState['trustee'],
                [e.target.id]: e.target.value
            }
        }))
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault()

        setLoading(true)

        if (formData.edit) {
            await updateTrustee(formData.trustee as ITrustee)
                .then((payload: any) => {
                    if (payload.data) {
                        closeModalRef.current.click()
                    }
                })
        } else {
            await createTrustee(formData.trustee as ITrustee)
                .then((payload: any) => {
                    if (payload.data) {
                        setPage(1)
                        closeModalRef.current.click()
                    }
                })
        }
    }

    return (
        <>
            <input type="checkbox" id="trustee-modal" className="modal-toggle"/>
            <div className="modal">
                <div className="modal-box">
                    <label ref={closeModalRef} htmlFor="trustee-modal" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
                    <form>
                        <div className="form-control">
                            <label className="label">
                                <span className="label-text text-xs sm:text-sm uppercase">* სახელი და გვარი</span>
                            </label>
                            <input
                                id="name"
                                type="text"
                                className="input input-sm sm:input-md input-bordered"
                                value={formData.trustee.name}
                                onChange={onChange}
                                disabled={loading}
                            />
                        </div>
                        <div className="form-control">
                            <label className="label">
                                <span className="label-text text-xs sm:text-sm uppercase">* პირადი ნომერი</span>
                            </label>
                            <input
                                id="personal_number"
                                type="text"
                                className="input input-sm sm:input-md input-bordered"
                                value={formData.trustee.personal_number}
                                onChange={onChange}
                                disabled={loading}
                            />
                        </div>
                        <div className="form-control">
                            <label className="label">
                                <span className="label-text text-xs sm:text-sm uppercase">* ტელეფონის ნომერი</span>
                            </label>
                            <input
                                id="phone"
                                type="text"
                                className="input input-sm sm:input-md input-bordered"
                                value={formData.trustee.phone}
                                onChange={onChange}
                                disabled={loading}
                            />
                        </div>
                        <div className="flex justify-center md:col-span-2 mt-3 md:mt-2">
                            <button className={'btn btn-sm sm:btn-md btn-primary uppercase' + (loading ? ' loading' : '')} onClick={handleSubmit}>{formData.btnText}</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default TrusteeModal;