import React, {FC, useContext} from 'react';
import {ITrustee} from "../models/ITrustee";
import {TrusteeContext} from "../context/TrusteeContex";
import {LoadingContext} from "../context/LoadingContex";

interface TrusteeItemProps {
    trustee: ITrustee
}

const Trustee: FC<TrusteeItemProps> = ({trustee}) => {
    const {deleteTrustee, setFormData} = useContext(TrusteeContext)
    const {setLoading} = useContext(LoadingContext)

    const handleUpdate = () => {
        setFormData({
            trustee,
            edit: true,
            btnText: 'რედაქტირება'
        })
    }

    const handleRemove = async () => {
        setLoading(true)

        await deleteTrustee(trustee)
    }

    return (
        <div className="bg-base-200 py-3 pl-4 pr-10 rounded shadow-xl relative mt-6">
            <div className="uppercase mb-2">{trustee.name}</div>
            <div className="text-xs mb-1">პირადი ნომერი: {trustee.personal_number}</div>
            <div className="text-xs">ტელეფონის ნომერი: {trustee.phone}</div>
            <div className="absolute top-0 right-0 bottom-0 flex flex-col justify-between p-2">
                <button className="btn btn-xs btn-square btn-ghost" onClick={handleRemove}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="red">
                        <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                    </svg>
                </button>
                <label htmlFor="trustee-modal" className="btn btn-xs btn-square btn-ghost" onClick={handleUpdate}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                        <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                    </svg>
                </label>
            </div>
        </div>
    );
};

export default Trustee;