import React, {FC, useContext} from 'react';
import RSLogo from "../assets/images/RS_logo.png";
import {IParcel} from '../models/IParcel';
import {AuthContext} from "../context/AuthContex";
import {ParcelContext} from "../context/ParcelContex";
import {LoadingContext} from "../context/LoadingContex";

interface ParcelItemProps {
    parcel: IParcel
}

const Parcel: FC<ParcelItemProps> = ({parcel}) => {
    const {auth} = useContext(AuthContext)
    const {deleteParcel, setRecipient, setDeclaration, setInvoice, payParcels} = useContext(ParcelContext)
    const {setLoading} = useContext(LoadingContext)

    const handleInvoice = () => {
        setInvoice(parcel)
    }

    const handleRecipient = () => {
        setRecipient({
            id: parcel.id,
            name: parcel.recipient?.name ?? '',
            personal_number: parcel.recipient?.personal_number ?? '',
            phone: parcel.recipient?.phone ?? ''
        })
    }

    const handleDeclaration = () => {
        setDeclaration({
            id: parcel.id,
            city_id: parcel.city_id,
            files: parcel.files ?? [],
            newFiles: [],
            seller: parcel.seller ?? '',
            products: parcel.products ?? [],
            needs_customs_clearance: parcel.needs_customs_clearance,
            disabled: !parcel.can_declare,
            tracking: '',
            pre: false
        })
    }

    const handlePay = async (e: any) => {
        e.preventDefault()

        setLoading(true)

        await payParcels([parcel.id])
    }

    const handleRemove = async (e: any) => {
        e.preventDefault()

        setLoading(true)

        await deleteParcel(parcel)
    }

    return (
        <div className="mt-3">
            {parcel.status !== 'draft' &&
                <div className="flex h-7 items-center justify-between px-1">
                    <div>{parcel.shipping?.code}</div>
                    {parcel.physical_weight > 0 && parcel.charge_weight > 0 &&
                        <div className="flex gap-3">
                            <div className="flex gap-1 items-center tooltip tooltip-left" data-tip="ფიზიკური წონა">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3" />
                                </svg>
                                <span className="text-xs">{parcel.physical_weight} კგ</span>
                            </div>
                            <div className="flex gap-1 items-center tooltip tooltip-left" data-tip="მოცულობითი წონა">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 inline" fill="currentColor" viewBox="0 0 24 24">
                                    <path fillRule="evenodd" d="M21.993 7.95a.96.96 0 0 0-.029-.214c-.007-.025-.021-.049-.03-.074-.021-.057-.04-.113-.07-.165-.016-.027-.038-.049-.057-.075-.032-.045-.063-.091-.102-.13-.023-.022-.053-.04-.078-.061-.039-.032-.075-.067-.12-.094-.004-.003-.009-.003-.014-.006l-.008-.006-8.979-4.99a1.002 1.002 0 0 0-.97-.001l-9.021 4.99c-.003.003-.006.007-.011.01l-.01.004c-.035.02-.061.049-.094.073-.036.027-.074.051-.106.082-.03.031-.053.067-.079.102-.027.035-.057.066-.079.104-.026.043-.04.092-.059.139-.014.033-.032.064-.041.1a.975.975 0 0 0-.029.21c-.001.017-.007.032-.007.05V16c0 .363.197.698.515.874l8.978 4.987.001.001.002.001.02.011c.043.024.09.037.135.054.032.013.063.03.097.039a1.013 1.013 0 0 0 .506 0c.033-.009.064-.026.097-.039.045-.017.092-.029.135-.054l.02-.011.002-.001.001-.001 8.978-4.987c.316-.176.513-.511.513-.874V7.998c0-.017-.006-.031-.007-.048zm-10.021 3.922L5.058 8.005 7.82 6.477l6.834 3.905-2.682 1.49zm.048-7.719L18.941 8l-2.244 1.247-6.83-3.903 2.153-1.191zM13 19.301l.002-5.679L16 11.944V15l2-1v-3.175l2-1.119v5.705l-7 3.89z" clipRule="evenodd" />
                                </svg>
                                <span className="text-xs">{parcel.charge_weight} კგ</span>
                            </div>
                        </div>
                    }
                </div>
            }
            <div className="bg-base-200 py-2 px-3 sm:py-3 sm:px-4 flex items-center justify-between rounded shadow">
                <div className="flex gap-3 items-center">
                    <div className="avatar placeholder">
                        {parcel.status === 'customs' &&
                            <div className="absolute w-4 sm:w-6 top-1 sm:top-0 left-1 sm:left-0" style={{marginTop:"-5px", marginLeft:"-10px"}}><img src={RSLogo} alt="RS"/></div>
                        }
                        <div className={"rounded w-12 sm:w-16" + (parcel.status === 'terminal' ? ' bg-red-700' : ' bg-base-100')}>
                            <span className="text-base sm:text-xl">{parcel.shipping ? parcel.shipping.country : '?'}</span>
                        </div>
                    </div>
                    <div className="flex flex-col gap-1">
                        <div className="flex items-center">
                            {parcel.recipient &&
                                <div className="dropdown dropdown-start mr-1">
                                    <label tabIndex={0} className="cursor-pointer hover:opacity-90 focus:opacity-70">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z" clipRule="evenodd" />
                                        </svg>
                                    </label>
                                    <div tabIndex={0} className="dropdown-content shadow bg-base-300 rounded">
                                        <div className="p-3 text-xs">
                                            <p className="uppercase whitespace-nowrap">{parcel.recipient.name}</p>
                                            {parcel.recipient.personal_number && <p>{parcel.recipient.personal_number}</p>}
                                            {parcel.recipient.phone && <p>{parcel.recipient.phone}</p>}
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className={"text-xs sm:text-sm break-all" + (!parcel.is_declared && ' text-red-500')}>{parcel.tracking}</div>
                        </div>
                        {parcel.shipping &&
                            <div className={"text-xs sm:text-md " + (parcel.is_paid ? 'text-green-500' : 'text-red-500')}>
                                <div className="tooltip" data-tip={"კურსი: " + parcel.shipping.currency_rate + ", ტარიფი: " + parcel.shipping.shipping_rate}>{parcel.shipping.price} <span className="text-2xs uppercase">ლარი</span></div>
                            </div>
                        }
                    </div>
                </div>
                <div className="dropdown dropdown-left">
                    <label tabIndex={0} className="btn btn-sm btn-square btn-ghost focus:bg-base-100">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z" />
                        </svg>
                    </label>
                    <ul tabIndex={0} className="dropdown-content menu p-1 shadow bg-base-100 rounded w-auto mr-1">
                        {(auth.type === 'TRA' && parcel.can_declare) && <li><label htmlFor="recipient-modal" className="whitespace-nowrap uppercase text-2xs sm:text-sm" onClick={handleRecipient}>მიმღები</label></li>}
                        {parcel.status !== 'draft' && <li><label htmlFor="invoice-modal" className="whitespace-nowrap uppercase text-2xs sm:text-sm" onClick={handleInvoice}>ინვოისი</label></li>}
                        {parcel.status !== 'draft' && !parcel.is_paid && <li><a href="/" onClick={handlePay} className="whitespace-nowrap uppercase text-2xs sm:text-sm">გადახდა</a></li>}
                        <li><label htmlFor="declaration-modal" className="whitespace-nowrap uppercase text-2xs sm:text-sm" onClick={handleDeclaration}>{parcel.status === 'draft' ? 'წინასწარი დეკლარირება' : 'დეკლარირება'}</label></li>
                        {parcel.status === 'draft' && <li><a href="/" className="whitespace-nowrap uppercase text-2xs sm:text-sm" onClick={handleRemove}>წაშლა</a></li>}
                    </ul>
                </div>
            </div>
            <div className="flex gap-3 h-7 items-center px-1">
                <div className="flex gap-1 items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 inline" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd" />
                    </svg>
                    <span className="text-xs">{parcel.received_at}</span>
                </div>
                {parcel.shipping && parcel.shipping.departure &&
                    <div className="flex gap-1 items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 inline" fill="currentColor" viewBox="0 0 24 24">
                            <path fillRule="evenodd" d="M3 18h18v2H3zm18.509-9.473a1.61 1.61 0 0 0-2.036-1.019L15 9 7 6 5 7l6 4-4 2-4-2-1 1 4 4 14.547-5.455a1.611 1.611 0 0 0 .962-2.018z" clipRule="evenodd" />
                        </svg>
                        <span className="text-xs">{parcel.shipping.departure}</span>
                    </div>
                }
                {parcel.shipping && parcel.shipping.arrival &&
                    <div className="flex gap-1 items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 inline" fill="currentColor"
                             viewBox="0 0 24 24">
                            <path fillRule="evenodd"
                                  d="M18.842 15.296a1.61 1.61 0 0 0 1.892-1.189v-.001a1.609 1.609 0 0 0-1.177-1.949l-4.576-1.133L9.825 4.21l-2.224-.225 2.931 6.589-4.449-.449-2.312-3.829-1.38.31 1.24 5.52 15.211 3.17zM3 18h18v2H3z"
                                  clipRule="evenodd"/>
                        </svg>
                        <span className="text-xs">{parcel.shipping.arrival}</span>
                    </div>
                }
            </div>
        </div>
    );
};

export default Parcel;