import React, {useContext} from 'react';
import {Link, LinkProps, useMatch, useResolvedPath} from "react-router-dom";
import {parcelAPI} from "../services/ParcelService";
import {ParcelContext} from "../context/ParcelContex";

const ParcelsNav = () => {
    const {data: quantities} = parcelAPI.useFetchQuantitiesQuery(null)

    const {page, setPage} = useContext(ParcelContext)

    const onClick = () => {
        if (page !== 1) setPage(1)
    }

    const CustomLink = ({ children, to, ...props }: LinkProps) => {
        let resolved = useResolvedPath(to)
        let match = useMatch({ path: resolved.pathname, end: true })

        return (
            <Link
                className={'indicator w-full uppercase' + (match ? ' active' : ' bg-base-200 hover:bg-base-300')}
                to={to}
                onClick={onClick}
                {...props}
            >
                {children}
            </Link>
        )
    }

    return (
        <div className="text-center mb-4">
            <ul className="menu menu-compact sm:menu-normal menu-vertical md:menu-horizontal rounded shadow">
                <li>
                    <CustomLink to='/parcels'>
                        <span className="indicator-item indicator-middle md:indicator-top md:indicator-end right-7 badge border-none">{quantities?.draft}</span>
                        მისაღები
                    </CustomLink>
                </li>
                <li>
                    <CustomLink to='/parcels/warehouse'>
                        <span className="indicator-item indicator-middle md:indicator-top md:indicator-end right-7 badge border-none">{quantities?.warehouse}</span>
                        საწყობში
                    </CustomLink>
                </li>
                <li>
                    <CustomLink to='/parcels/transit'>
                        <span className="indicator-item indicator-middle md:indicator-top md:indicator-end right-7 badge border-none">{quantities?.transit}</span>
                        გამოგზავნილი
                    </CustomLink>
                </li>
                <li>
                    <CustomLink to='/parcels/arrived'>
                        <span className="indicator-item indicator-middle md:indicator-top md:indicator-end right-7 badge border-none">{quantities?.arrived}</span>
                        ჩამოსული
                    </CustomLink>
                </li>
                <li>
                    <CustomLink to='/parcels/delivered'>
                        <span className="indicator-item indicator-middle md:indicator-top md:indicator-end right-7 badge border-none">{quantities?.delivered}</span>
                        მიღებული
                    </CustomLink>
                </li>
            </ul>
        </div>
    );
};

export default ParcelsNav;