import {useContext, useEffect} from "react";
import {parcelAPI} from "../services/ParcelService";
import {AuthContext} from "../context/AuthContex";

export function useFetchFinances() {
    const {data: finances, isFetching} = parcelAPI.useFetchFinancesQuery(null)

    const {setFinances} = useContext(AuthContext)

    useEffect(() => {
        if (finances) {
            setFinances(finances)
        }
        // eslint-disable-next-line
    }, [isFetching])

    return finances;
}