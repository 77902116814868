import React from 'react';

const RecordNotFound = () => {
    return (
        <div className="alert shadow-lg rounded mt-6">
            <span>ჩანაწერი არ მოიძებნა.</span>
        </div>
    );
};

export default RecordNotFound;