import React, {FC, useContext, useRef} from 'react';
import Logo from "../assets/images/logo.svg";
import {Link, useMatch, useResolvedPath} from "react-router-dom";
import type {LinkProps} from "react-router-dom";
import BalanceModal from "./Modals/BalanceModal";
import {AuthContext} from "../context/AuthContex";

interface SidebarProps {
    menuIsOpen: boolean
}

const Sidebar: FC<SidebarProps> = ({menuIsOpen}) => {
    const drawerOverlayRef = useRef() as any

    const {finances} = useContext(AuthContext)

    const handleClick = () => {
        if (menuIsOpen) {
            drawerOverlayRef.current.click()
        }
    }

    const CustomLink = ({ children, to, ...props }: LinkProps) => {
        let resolved = useResolvedPath(to)
        let match = useMatch({ path: resolved.pathname, end: resolved.pathname === '/' })

        return (
            <Link
                className={'w-full uppercase' + (match ? ' active pointer-events-none' : '')}
                to={to}
                onClick={handleClick}
                {...props}
            >
                {children}
            </Link>
        )
    }

    return (
        <div className="drawer-side" style={{ scrollBehavior: "smooth", scrollPaddingTop: "5rem" }}>
            <label ref={drawerOverlayRef} htmlFor="drawer" className="drawer-overlay" />
            <aside className="bg-base-200 w-80">
                <div className="z-20 bg-base-200 bg-opacity-90 backdrop-blur sticky top-0 items-center gap-2 px-4 py-2 hidden lg:flex">
                    <Link to="/" aria-current="page" aria-label="Homepage" className="flex-0 btn btn-ghost px-2">
                        <img className="block h-10" src={Logo} alt="Transporter" />
                    </Link>
                </div>
                <div className="h-5" />
                <div className="px-4">
                    <div className="stats stats-vertical w-full shadow rounded">
                        <div className="stat">
                            <div className="stat-figure">
                                <label htmlFor="balance-modal" className="btn btn-sm btn-circle btn-primary modal-button">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                    </svg>
                                </label>
                            </div>
                            <div className="stat-title uppercase">ბალანსი</div>
                            <div className="stat-value text-xl">{finances.balance} <span className="text-xs uppercase">ლარი</span></div>
                        </div>
                        <div className="stat">
                            <div className="stat-title uppercase">გადასახდელი</div>
                            <div className="stat-value text-xl text-red-500">{finances.unpaid} <span className="text-xs uppercase">ლარი</span></div>
                        </div>
                    </div>
                </div>
                <div className="h-10" />
                <ul className="menu menu-compact flex flex-col py-0 px-4">
                    <li>
                        <CustomLink to="/">
                            <span className="flex-none">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                                </svg>
                            </span>
                            <span className="flex-1 uppercase">მთავარი</span>
                        </CustomLink>
                    </li>
                    <li>
                        <CustomLink to="/parcels">
                            <span className="flex-none">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4" />
                                </svg>
                            </span>
                            <span className="flex-1 uppercase">ამანათები</span>
                        </CustomLink>
                    </li>
                    <li>
                        <CustomLink to="/trustees">
                            <span className="flex-none">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                                </svg>
                            </span>
                            <span className="flex-1 uppercase">მინდობილი პირები</span>
                        </CustomLink>
                    </li>
                    <li>
                        <CustomLink to="/addresses">
                            <span className="flex-none">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                                </svg>
                            </span>
                            <span className="flex-1 uppercase">თქვენი მისამართები</span>
                        </CustomLink>
                    </li>
                    <li>
                        <CustomLink to="/deliveries">
                            <span className="flex-none">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 18.75a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 01-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 00-3.213-9.193 2.056 2.056 0 00-1.58-.86H14.25M16.5 18.75h-2.25m0-11.177v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 00-10.026 0 1.106 1.106 0 00-.987 1.106v7.635m12-6.677v6.677m0 4.5v-4.5m0 0h-12" />
                                </svg>
                            </span>
                            <span className="flex-1 uppercase">საკურიერო მომსახურება</span>
                        </CustomLink>
                    </li>
                    <li>
                        <CustomLink to="/transactions">
                            <span className="flex-none">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z" />
                                </svg>
                            </span>
                            <span className="flex-1 uppercase">ტრანზაქციები</span>
                        </CustomLink>
                    </li>
                    <li>
                        <CustomLink to="/settings">
                            <span className="flex-none">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                </svg>
                            </span>
                            <span className="flex-1 uppercase">პარამეტრები</span>
                        </CustomLink>
                    </li>
                    {/*<li>
                        <CustomLink to="/logout">
                            <span className="flex-none">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                                </svg>
                            </span>
                            <span className="flex-1 uppercase">გასვლა</span>
                        </CustomLink>
                    </li>*/}
                </ul>
                <div className="h-10" />
            </aside>
            <BalanceModal />
        </div>
    );
};

export default Sidebar;