import React, {useContext, useRef} from 'react';
import {IRecipient} from "../../models/IRecipient";
import {ParcelContext} from "../../context/ParcelContex";
import {LoadingContext} from "../../context/LoadingContex";

const RecipientModal = () => {
    const closeModalRef = useRef() as any
    const {updateParcelRecipient, recipient, setRecipient} = useContext(ParcelContext)
    const {loading, setLoading} = useContext(LoadingContext)

    const onChange = (e: any) => {
        setRecipient((prevState: any) => ({
            ...prevState,
            [e.target.id]: e.target.value,
        }))
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault()

        setLoading(true)

        await updateParcelRecipient(recipient as IRecipient)
            .then((payload: any) => {
                if (payload.data) {
                    closeModalRef.current.click()
                }
            })
    }

    return (
        <>
            <input type="checkbox" id="recipient-modal" className="modal-toggle"/>
            <div className="modal">
                <div className="modal-box">
                    <label ref={closeModalRef} htmlFor="recipient-modal" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
                    <form>
                        <div className="form-control">
                            <label className="label">
                                <span className="label-text text-xs sm:text-sm uppercase">* სახელი და გვარი</span>
                            </label>
                            <input
                                id="name"
                                type="text"
                                className="input input-sm sm:input-md input-bordered"
                                value={recipient.name}
                                onChange={onChange}
                                disabled={loading}
                            />
                        </div>
                        <div className="form-control">
                            <label className="label">
                                <span className="label-text text-xs sm:text-sm uppercase">* პირადი ნომერი</span>
                            </label>
                            <input
                                id="personal_number"
                                type="text"
                                className="input input-sm sm:input-md input-bordered"
                                value={recipient.personal_number}
                                onChange={onChange}
                                disabled={loading}
                            />
                        </div>
                        <div className="form-control">
                            <label className="label">
                                <span className="label-text text-xs sm:text-sm uppercase">* ტელეფონის ნომერი</span>
                            </label>
                            <input
                                id="phone"
                                type="text"
                                className="input input-sm sm:input-md input-bordered"
                                value={recipient.phone}
                                onChange={onChange}
                                disabled={loading}
                            />
                        </div>
                        <div className="modal-action justify-center">
                            <button className={'btn btn-sm sm:btn-md btn-primary uppercase' + (loading ? ' loading' : '')} onClick={handleSubmit}>შენახვა</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default RecipientModal;