import React, {useContext, useEffect, useState} from 'react';
import {LoadingContext} from "../context/LoadingContex";
import RecordNotFound from "../components/RecordNotFound";
import Pagination from "../components/Pagination";
import {useFetchFinances} from "../hooks/useFetchFinances";
import {parcelAPI} from "../services/ParcelService";
import {DeliveryContext} from "../context/DeliveryContex";
import DeliveryModal from "../components/Modals/DeliveryModal";

const Transactions = () => {
    useFetchFinances()

    const initialState = {
        delivery: {
            user_address_id: 0,
            parcels: [],
            weights: []
        },
        btnText: 'გადახდა'
    }

    const [page, setPage] = useState(1)
    const {data: deliveries, error, isFetching} = parcelAPI.useFetchDeliveriesQuery(page)
    const [payDelivery] = parcelAPI.usePayDeliveryMutation()
    const {setLoading} = useContext(LoadingContext)

    useEffect(() => {
        setLoading(isFetching)
        // eslint-disable-next-line
    }, [isFetching])

    const [formData, setFormData] = useState(initialState)

    return (
        <DeliveryContext.Provider value={{
            payDelivery,
            formData,
            setFormData,
            setPage
        }}>
            <label htmlFor="delivery-modal" className="btn btn-sm sm:btn-md btn-primary uppercase" onClick={() => setFormData(initialState)}>კურიერის გამოძახება</label>
            {error && <h1>Failed to load data</h1>}
            {deliveries && deliveries.data.length === 0 &&
                <div className="md:grid md:grid-cols-2 xl:grid-cols-3 gap-x-6 mt-4">
                    <RecordNotFound />
                </div>
            }
            <div className="md:grid md:grid-cols-2 gap-x-6 md:gap-y-4 mt-4">
                {deliveries && deliveries.data.map((delivery, index) =>
                    <div key={index} className="mt-4 md:mt-0">
                        <div className="flex h-7 items-center justify-between px-1">
                            {delivery.address.phone &&
                                <div className="flex gap-1 items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-3.5 w-3.5 inline">
                                        <path fillRule="evenodd" d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z" clipRule="evenodd" />
                                    </svg>
                                    <span className="text-sm">{delivery.address.phone}</span>
                                </div>
                            }
                            <div className="flex gap-3">
                                <div className="flex gap-1 items-center tooltip tooltip-left" data-tip={`${delivery.parcels.length > 0 ? delivery.parcels.join(', ') : 'ამანათების რაოდენობა'}`}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 6.878V6a2.25 2.25 0 012.25-2.25h7.5A2.25 2.25 0 0118 6v.878m-12 0c.235-.083.487-.128.75-.128h10.5c.263 0 .515.045.75.128m-12 0A2.25 2.25 0 004.5 9v.878m13.5-3A2.25 2.25 0 0119.5 9v.878m0 0a2.246 2.246 0 00-.75-.128H5.25c-.263 0-.515.045-.75.128m15 0A2.25 2.25 0 0121 12v6a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 18v-6c0-.98.626-1.813 1.5-2.122" />
                                    </svg>
                                    <span className="text-xs">{delivery.quantity} ცალი</span>
                                </div>
                                <div className="flex gap-1 items-center tooltip tooltip-left" data-tip="ამანათების წონა">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 inline" fill="currentColor" viewBox="0 0 24 24">
                                        <path fillRule="evenodd" d="M21.993 7.95a.96.96 0 0 0-.029-.214c-.007-.025-.021-.049-.03-.074-.021-.057-.04-.113-.07-.165-.016-.027-.038-.049-.057-.075-.032-.045-.063-.091-.102-.13-.023-.022-.053-.04-.078-.061-.039-.032-.075-.067-.12-.094-.004-.003-.009-.003-.014-.006l-.008-.006-8.979-4.99a1.002 1.002 0 0 0-.97-.001l-9.021 4.99c-.003.003-.006.007-.011.01l-.01.004c-.035.02-.061.049-.094.073-.036.027-.074.051-.106.082-.03.031-.053.067-.079.102-.027.035-.057.066-.079.104-.026.043-.04.092-.059.139-.014.033-.032.064-.041.1a.975.975 0 0 0-.029.21c-.001.017-.007.032-.007.05V16c0 .363.197.698.515.874l8.978 4.987.001.001.002.001.02.011c.043.024.09.037.135.054.032.013.063.03.097.039a1.013 1.013 0 0 0 .506 0c.033-.009.064-.026.097-.039.045-.017.092-.029.135-.054l.02-.011.002-.001.001-.001 8.978-4.987c.316-.176.513-.511.513-.874V7.998c0-.017-.006-.031-.007-.048zm-10.021 3.922L5.058 8.005 7.82 6.477l6.834 3.905-2.682 1.49zm.048-7.719L18.941 8l-2.244 1.247-6.83-3.903 2.153-1.191zM13 19.301l.002-5.679L16 11.944V15l2-1v-3.175l2-1.119v5.705l-7 3.89z" clipRule="evenodd" />
                                    </svg>
                                    <span className="text-xs">{delivery.weight} კგ</span>
                                </div>
                            </div>
                        </div>
                        <div className="bg-base-200 py-2 px-3 sm:py-3 sm:px-4 flex items-center justify-between rounded shadow">
                            <div className="flex gap-3 items-center">
                                <div className="avatar placeholder">
                                    <div className="rounded w-12 sm:w-16 bg-base-100">
                                        <span className="text-base sm:text-md uppercase-transform">
                                            {delivery.status === 'pending' &&
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-8 h-8">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                </svg>
                                            }
                                            {delivery.status === 'delivered' &&
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 sm:w-8 sm:h-8">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                                </svg>
                                            }
                                            {delivery.status === 'canceled' &&
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 sm:w-8 sm:h-8">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                                </svg>
                                            }
                                        </span>
                                    </div>
                                </div>
                                <div className="flex flex-col gap-1">
                                    <div className="flex items-center">
                                        <div className="text-xs sm:text-sm break-all uppercase">{delivery.address.name} {delivery.address.personal_number && '- ' + delivery.address.personal_number}</div>
                                    </div>
                                    <div className="text-xs sm:text-md">
                                        <div>{delivery.address.city}, {delivery.address.address}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="inline-flex items-center text-base">
                                {delivery.price} <span className="ml-1 text-2xs">ლარი</span>
                            </div>
                        </div>
                        <div className="flex gap-3 h-7 items-center px-1">
                            <div className="flex gap-1 items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 inline" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd" />
                                </svg>
                                <span className="text-xs">{delivery.created_at}</span>
                            </div>
                            {delivery.delivered_at &&
                                <div className="flex gap-1 items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 inline" viewBox="0 0 24 24" fill="currentColor">
                                        <path fillRule="evenodd" d="M7.502 6h7.128A3.375 3.375 0 0118 9.375v9.375a3 3 0 003-3V6.108c0-1.505-1.125-2.811-2.664-2.94a48.972 48.972 0 00-.673-.05A3 3 0 0015 1.5h-1.5a3 3 0 00-2.663 1.618c-.225.015-.45.032-.673.05C8.662 3.295 7.554 4.542 7.502 6zM13.5 3A1.5 1.5 0 0012 4.5h4.5A1.5 1.5 0 0015 3h-1.5z" clipRule="evenodd" />
                                        <path fillRule="evenodd" d="M3 9.375C3 8.339 3.84 7.5 4.875 7.5h9.75c1.036 0 1.875.84 1.875 1.875v11.25c0 1.035-.84 1.875-1.875 1.875h-9.75A1.875 1.875 0 013 20.625V9.375zm9.586 4.594a.75.75 0 00-1.172-.938l-2.476 3.096-.908-.907a.75.75 0 00-1.06 1.06l1.5 1.5a.75.75 0 001.116-.062l3-3.75z" clipRule="evenodd" />
                                    </svg>
                                    <span className="text-xs">{delivery.delivered_at}</span>
                                </div>
                            }
                        </div>
                    </div>
                )}
            </div>
            {deliveries && deliveries.meta.last_page > 1 &&
                <Pagination currentPage={deliveries.meta.current_page} lastPage={deliveries.meta.last_page} setPage={setPage} />
            }
            <DeliveryModal />
        </DeliveryContext.Provider>
    );
};

export default Transactions;