import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";

export interface ILogin {
    email: string
    password: string
}

export interface IRegister {
    user_type: string
    email: string
    name: string
    name_ka: string
    code: string
    phone: string
    password: string
    password_confirmation: string
    city_id: number
}

export const authAPI = createApi({
    reducerPath: 'authAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_URL}`,
        credentials: "include",
        prepareHeaders: (headers) => {
            const auth = JSON.parse(localStorage.getItem('user') as any)

            if (auth?.token) {
                headers.set('authorization', `Bearer ${auth.token}`)
            }

            headers.set('accept', 'application/json')

            return headers
        }
    }),
    endpoints: (build) => ({
        login: build.mutation<ILogin, ILogin>({
            query: (credentials) => ({
                url: '/login',
                method: 'POST',
                body: credentials,
            }),
        }),
        register: build.mutation<IRegister, IRegister>({
            query: (body) => ({
                url: '/register',
                method: 'POST',
                body: body,
            }),
        }),
        logout: build.mutation({
            query: () => ({
                url: '/logout',
                method: 'DELETE',
            }),
        }),
        forgot: build.mutation({
            query: (email: string) => ({
                url: '/password/forgot',
                method: 'POST',
                body: {email: email},
            }),
        }),
        reset: build.mutation({
            query: (body) => ({
                url: `/password/reset`,
                method: 'POST',
                body: body
            }),
        }),
    })
})